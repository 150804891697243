import axios from 'axios'


class APIDomainBoost {

  getUrlDomainApi(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "api/" + nome_metodo;

  }



  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async insertSite(sito) {

    let formData = new FormData();

    for (var key in sito) {
      formData.append(key, sito[key]);
    }

    return axios.post(this.getUrlDomain("insertSito"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getSito(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("getSito"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateSite(sito, v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    for (var key in sito) {
      formData.append(key, sito[key]);
    }

    return axios.post(this.getUrlDomain("updateSito"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteSite(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteSito"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initInsertMetaTag(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("initInsertMetaTag"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async insertMetaTag(meta_tag) {

    let formData = new FormData();

    for (var key in meta_tag) {
      formData.append(key, meta_tag[key]);
    }

    return axios.post(this.getUrlDomain("insertMetaTag"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editMetaTag(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("editMetaTag"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateMetaTag(meta_tag, v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    for (var key in meta_tag) {
      formData.append(key, meta_tag[key]);
    }

    return axios.post(this.getUrlDomain("updateMetaTag"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteMetaTag(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteMetaTag"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initInsertConfig(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("initInsertConfig"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertConfig(conf_dboost) {

    let formData = new FormData();

    for (var key in conf_dboost) {
      formData.append(key, conf_dboost[key]);
    }

    return axios.post(this.getUrlDomain("insertConfig"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editConfig(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("editConfig"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateConfig(config_dboost, v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    for (var key in config_dboost) {
      formData.append(key, config_dboost[key]);
    }

    return axios.post(this.getUrlDomain("updateConfig"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteConfig(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteConfig"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getDomainFilter(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("getDomainFilter"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getDomainFilterWithAll(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("getDomainFilterWithAll"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async enableDisableConf(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("enableDisableConf"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteLogCron(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteLogCron"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteStatistics(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteStatistics"), formData, { 'Content-Type': 'multipart/form-data' });

  }





}

export default new APIDomainBoost({
  url: "http://emtool.local/public/api/smartbook/"
})
