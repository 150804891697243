<template>
    <div>


        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">

                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <!-- START -->
                                <div class="sidemenu__sub-items-container" id="menu-12">


                                    <div class="sidemenu__group-container">
                                        <div class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezManage" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left:5px;padding-top: 5px;">Manage</div>

                                        </div>

                                        <div style="border-top: 1px solid #6cc115;" v-if="viewSezManage == 'S'">

                                            <ul class="group__items">

                                                <a @click="goto('/domainBoostConfiguration')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/config_dboost.png" max-width="42" max-height="42"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Configuration</span>
                                                </a>


                                                <a @click="goto('/domainBoostSiti')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/sistes_dboost.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Domains</span>
                                                </a>

                                                <a @click="goto('/domainBoostLogCron')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/log_cron_dboost.png" max-width="40" max-height="40"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Log Cron</span>
                                                </a>



                                                <a @click="goto('/domainBoostMetaTag')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/meta_tag_dboost.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Meta Tag</span>
                                                </a>

                                                <!-- <a @click="goto('/filterDomainBoostStatistics')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/statistics_dboost.png" max-width="42" max-height="42"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Statistics</span>
                                                </a> -->
                                                
                                                <a @click="goto('/domainBoostStatistics')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/statistics_dboost.png" max-width="42" max-height="42"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Statistics</span>
                                                </a>



                                            </ul>

                                        </div>

                                    </div>


                                    <div class="sidemenu__group-container">
                                        <div class="group__text"
                                            style="border-color: #6cc115;display:flex;justify-items: center;height: 40px;padding-top: 10px;">
                                            <div style="margin-left:5px">Application</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="isPwdChangedOnce > 0" @click="goto('/dash')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain src="@/assets/home2.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />


                                                <span class="subitem__text">Home</span>
                                            </a>

                                            <a v-if="isPwdChangedOnce > 0" @click="goto('/info')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain src="@/assets/info2.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Info</span>
                                            </a>


                                            <a @click="logout" mid="mnu_gest_company" class="sidemenu__subitem"
                                                href="#">
                                                <v-img alt="Logout" class="  " contain src="@/assets/exit.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Exit</span>
                                            </a>

                                        </ul>
                                    </div>
                                </div>

                                <!-- END -->

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>
</template>

<script>
//import $ from 'jquery';
import _ from 'lodash';
import apiusers from "../utils/users/apiusers";
//import router from ".././router";

export default {
    name: "menubottom",
    props: {},

    mounted: function () {

        this.$root.$children[0].colorProgress = "primary";

        this.setGlobal();

        var v_token = window.$cookies.get("token");
        var v_models = window.$cookies.get("models");
        var v_system = window.$cookies.get("system");
        var v_is_customer = window.$cookies.get("is_customer");





        if (v_system != "null") {
            this.hasResAssociated = 1;
        }

        console.log("MODELS: ", v_models);


        console.log("TOKEN: ", v_token);

        console.log("TOKEN DEC: ", atob(v_token));

        this.curUser = atob(v_token);


        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");
        var user_models = window.$cookies.get("user_models");
        this.isCustSupp = window.$cookies.get("is_cust_supp");
        this.isAgila = window.$cookies.get("is_agila");
        this.isPwdChangedOnce = window.$cookies.get("is_pwd_changed_once");

        console.log("USER MODELS: ", user_models);
        console.log("IS AGILA: ", this.isAgila);

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }



        console.log("IS SU: ", this.isSU);


        if (this.isSU == 0) {

            this.viewGestioneUtenti = this.getEnableConf("manage_users");
            this.viewEditTimesheet = this.getEnableConf("edit_timesheet");
            this.viewEditDisease = this.getEnableConf("edit_disease");
            this.viewEditHolidaysPermits = this.getEnableConf("edit_holidays_permits");
            this.viewEditProfile = this.getEnableConf("edit_profilo");
            this.viewGestioneCespiti = this.getEnableConf("manage_cespiti");
            this.viewGestioneSlider = this.getEnableConf("manage_slider");
            this.viewGestionePerfImp = this.getEnableConf("manage_perf_imp");
            this.viewGestionePerfRis = this.getEnableConf("manage_perf_risorse");
            this.viewInvioPresenze = this.getEnableConf("invio_presenze");
            this.viewAziNormInAgilaSec = this.getEnableConf("manage_azi_in_agila_sec");
            this.viewAziAgila = this.getEnableConf("manage_azi_agila");
            this.viewCommercialisti = this.getEnableConf("manage_commercialisti");
            this.viewControlli = this.getEnableConf("manage_controlli");
            this.viewControlliStandard = this.getEnableConf("manage_controlli_standard");
            this.viewDescrModFatt = this.getEnableConf("manage_descr_mod_fatt");
            this.viewModFatt = this.getEnableConf("manage_mod_fatt");
            this.viewPratiche = this.getEnableConf("manage_pratiche");
            this.viewReferenti = this.getEnableConf("manage_referenti");
            this.viewScadenze = this.getEnableConf("manage_scadenze");
            this.viewScadenzeStandard = this.getEnableConf("manage_scadenze_standard");
            this.viewSediOperative = this.getEnableConf("manage_sedi_operative");
            this.viewSettoriAttvita = this.getEnableConf("manage_sett_att");
            this.viewStatiPagamento = this.getEnableConf("manage_stati_pag");
            this.viewStatoPratiche = this.getEnableConf("manage_stato_pratiche");
            this.viewTipoControllo = this.getEnableConf("manage_tipo_controllo");
            this.viewTipoProgetto = this.getEnableConf("manage_tipo_progetto");
            this.viewConfProd = this.getEnableConf("conf_prod");
            this.viewGruppi = this.getEnableConf("manage_gruppi");
            this.viewLogoFirma = this.getEnableConf("logo_firma");
            this.viewPianoOrario = this.getEnableConf("piano_orario");
            this.viewUtentiAzienda = this.getEnableConf("utenti_azienda");
            this.viewClientiFornitori = this.getEnableConf("clienti_fornitori");
            this.viewAziendeEmt = this.getEnableConf("aziende_emt");
            this.viewAziendeBasic = this.getEnableConf("aziende_basic");
            this.viewTsCalendario = this.getEnableConf("calendario_timesheet");
            this.viewCreazGlobale = this.getEnableConf("creazione_globale_timesheet");
            this.viewRefEmt = this.getEnableConf("referenti_emt");
            this.viewRisorse = this.getEnableConf("manage_risorse");
            this.viewTsFornitore = this.getEnableConf("timesheet_fornitori");
            this.viewVerTs = this.getEnableConf("verifica_timesheet");
            this.viewVerTsEbadge = this.getEnableConf("verifica_ebadge");
            this.viewReminderTimesheet = this.getEnableConf("reminder_timesheet");
            this.viewReminderHolidays = this.getEnableConf("reminder_ferie_permessi");
            this.viewTipoCespiti = this.getEnableConf("tipi_cespiti_emt");
            this.viewTsClienti = this.getEnableConf("timesheet_clienti");

            console.log("GESTIONE UTENTI: ", this.viewGestioneUtenti);
            console.log("EDIT TIMESHEET: ", this.viewEditTimesheet);
            console.log("EDIT DISEASE: ", this.viewEditDisease);
            console.log("EDIT HOLIDAYS PERMITS: ", this.viewEditHolidaysPermits);
            console.log("EDIT PROFILO: ", this.viewEditProfile);
            console.log("GESTIONE CESPITI: ", this.viewGestioneCespiti);
            console.log("GESTIONE SLIDER: ", this.viewGestioneSlider);
            console.log("GESTIONE PERFORM IMP: ", this.viewGestionePerfImp);
            console.log("GESTIONE PERFORM RIS: ", this.viewGestionePerfRis);
            console.log("INVIO PRES: ", this.viewInvioPresenze);

            if (v_is_customer == 1) {

                this.viewTsClienti = 1;
                this.isPwdChangedOnce = 1;

            }

        }
        else {

            this.viewGestioneUtenti = 1;
            this.viewEditTimesheet = 1;
            this.viewEditDisease = 1;
            this.viewEditHolidaysPermits = 1;
            this.viewEditProfile = 1;
            this.viewGestioneCespiti = 1;
            this.viewGestioneSlider = 1;
            this.viewGestionePerfImp = 1;
            this.viewGestionePerfRis = 1;
            this.viewInvioPresenze = 1;
            this.viewAziNormInAgilaSec = 1;
            this.viewAziAgila = 1;
            this.viewCommercialisti = 1;
            this.viewControlli = 1;
            this.viewControlliStandard = 1;
            this.viewDescrModFatt = 1;
            this.viewModFatt = 1;
            this.viewPratiche = 1;
            this.viewReferenti = 1;
            this.viewScadenze = 1;
            this.viewScadenzeStandard = 1;
            this.viewSediOperative = 1;
            this.viewSettoriAttvita = 1;
            this.viewStatiPagamento = 1;
            this.viewStatoPratiche = 1;
            this.viewTipoControllo = 1;
            this.viewTipoProgetto = 1;
            this.viewConfProd = 1;
            this.viewGruppi = 1;
            this.viewLogoFirma = 1;
            this.viewPianoOrario = 1;
            this.viewUtentiAzienda = 1;
            this.viewClientiFornitori = 1;
            this.viewAziendeEmt = 1;
            this.viewAziendeBasic = 1;
            this.viewTsCalendario = 1;
            this.viewCreazGlobale = 1;
            this.viewRefEmt = 1;
            this.viewRisorse = 1;
            this.viewTsFornitore = 1;
            this.viewVerTs = 1;
            this.viewVerTsEbadge = 1;
            this.viewReminderTimesheet = 1;
            this.viewReminderHolidays = 1;
            this.viewTipoCespiti = 1;
            this.viewTsClienti = 1;

        }


        if (this.isSU == 0) {

            var is_invite_cespiti = window.$cookies.get("is_invite_cespiti");

            console.log("INVITE CESPITI: ", is_invite_cespiti);

            if (is_invite_cespiti == 1) {

                this.viewGestioneCespiti = 1;

                this.viewEditTimesheet = 0;
                this.viewEditHolidaysPermits = 0;
                this.viewEditDisease = 0;

            }



        }






    },
    data() {

        return {

            viewSezUsers: "N",
            viewSezPaySlips: "N",
            viewSezManage: "S",


            viewGestioneUtenti: 0,
            viewEditTimesheet: 0,
            viewEditDisease: 0,
            viewEditHolidaysPermits: 0,
            viewEditProfile: 0,
            viewGestioneCespiti: 0,
            viewGestioneSlider: 0,
            viewGestionePerfImp: 0,
            viewGestionePerfRis: 0,
            viewInvioPresenze: 0,
            viewAziNormInAgilaSec: 0,
            viewAziAgila: 0,
            viewCommercialisti: 0,
            viewControlli: 0,
            viewControlliStandard: 0,
            viewDescrModFatt: 0,
            viewModFatt: 0,
            viewPratiche: 0,
            viewReferenti: 0,
            viewScadenze: 0,
            viewScadenzeStandard: 0,
            viewSediOperative: 0,
            viewSettoriAttvita: 0,
            viewStatiPagamento: 0,
            viewStatoPratiche: 0,
            viewTipoControllo: 0,
            viewTipoProgetto: 0,
            viewConfProd: 0,
            viewGruppi: 0,
            viewLogoFirma: 0,
            viewPianoOrario: 0,
            viewUtentiAzienda: 0,
            viewClientiFornitori: 0,
            viewAziendeEmt: 0,
            viewAziendeBasic: 0,
            viewTsCalendario: 0,
            viewCreazGlobale: 0,
            viewRefEmt: 0,
            viewRisorse: 0,
            viewTsFornitore: 0,
            viewVerTs: 0,
            viewVerTsEbadge: 0,
            viewReminderTimesheet: 0,
            viewReminderHolidays: 0,
            viewTipoCespiti: 0,
            viewTsClienti: 0,

            isCMBAdmin: 0,
            isCMBDip: 0,
            hasResAssociated: 0,

            curUser: "",


            isPwdChangedOnce: 0,
            isAgila: 0,
            isAdmin: 0,
            isSU: 0,
            isCustSupp: 0,
            sheetAziende: false,
            currentFile: null,
            selecting: 0,
            currItem: {},
            currGruppo: {},
            switch1: true,
            nomeCorrente: "",
            titolo: "",
            body: "",
            aziende: [],
            gruppi: [],
            globalArrAziende: [],
            dipendenti: [],
            search: '',
            sheetGruppi: false,
            sheetEmpty: false,
            sheetDipendenti: false,
            searchDipendenti: '',
            searchGruppi: '',

        };
    },

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },
    created() {
        this.intervalHandle = setInterval(this.tick, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalHandle);
    },
    methods: {

        checkConfTsDip: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.checkConfTsDip(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkConfTsDip", res);


                if (res.data.Result == "OK") {


                    if (res.data.n_proj > 1) {


                        if (res.data.IsEnableRep == "N") {

                            that.goto('/timesheetGrid');

                        }
                        else {

                            that.goto('/timesheetGridWithRep');

                        }
                    }

                    if (res.data.n_proj <= 1) {

                        if (res.data.IsEnableRep == "N") {

                            that.goto('/timesheetGridUnProj');

                        }
                        else {

                            that.goto('/timesheetGridUnProjWithRep');

                        }


                    }





                }
                else {

                    that.$swal({
                        icon: "warning",
                        text: "Piano orario non configurato chiedere all'amministrazione",
                        showConfirmButton: false,
                        timer: 3000
                    });


                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            if (v_product_permissions != null) {


                var elements = v_product_permissions.split("|");

                for (var x = 0; x <= elements.length - 1; x++) {

                    if (elements[x] == v_codice) {

                        result = 1;

                    }


                }

            }



            return result;

        },

        logout: function () {
            this.sheetAziende = false;
            this.$root.$children[0].logout();
        },


        gotoOld: function (link) {

            this.sheetAziende = false;
            return this.$router.push(link);
            //  this.$router.push('/profile')

        },



        goto: function (link) {

            this.sheetAziende = false;

            this.$root.$children[0].colorProgress = "primary";


            window.$cookies.set("page_reload", link, "9y");



            setTimeout(() => {

                return this.$router.push("/reloadPage");

            }, 200);

            // return this.$router.push("/reloadPage");
            //  this.$router.push('/profile')

        },


        setGlobal: function () {

            this.aziende = this.$root.$children[0].aziende;
            this.dipendenti = this.$root.$children[0].dipendenti;
            this.gruppi = this.$root.$children[0].gruppi;
            this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        },

        openmenu: function () {

            this.setGlobal();

            this.sheetAziende = true;
        },
        manageClose: function () {

            // this.setupButtons();
            this.sheetAziende = false;
            // this.sheetEmpty = false;
            this.sheetDipendenti = false;
            // this.sheetGruppi = false;

            this.$root.$children[0].bnavbuttons = this.$root.$children[0].old_bnavbuttons;

        },

    }
};
</script>

<style scoped>
/****** MENU ******/
.menu-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.menu__item .title,
.menu-icon .title {
    display: block;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .05em;
}

.menu__settings-icon {
    height: 26px !important;
    width: 26px !important;
    margin-bottom: 4px !important;
    margin-top: 2px !important;
}

#sidemenu {

    z-index: 2;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    position: fixed;
    top: 54px;
    left: 0;
    background: transparent;
    bottom: 56px;
    padding: 28px 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

#sidemenu.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

#sidemenu .sidemenu__inner-wrapper {
    overflow: hidden;
    background: white;
    height: 100%;
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
}

.sidemenu__top-level-item {
    width: 83px;
    text-align: center;
    border-bottom: 1px solid #efefef;
    border-right: 1px solid #efefef;
    opacity: 1;
}

sidemenu__top-level-item a {
    width: 80px;
}

.sidemenu__top-level-item>a {
    height: 80px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    padding: 8px;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
}

.sidemenu__top-level-item>a:before {
    position: absolute;
    top: 0;
    left: -3px;
    height: 100%;
    width: 3px;
    z-index: 1;
    content: '';
    display: block;
}

.sidemenu__top-level-item>a:hover {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    opacity: .7;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    display: block;
    margin-bottom: 4px;
    width: 30px;
}

.sidemenu__top-level-item:hover {
    background-color: #f9f9f9;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__icon {
    -webkit-filter: none;
    filter: none;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__text {
    opacity: 1;
}

/*
  .sidemenu__sub-items-container {
    z-index: -1;
    position: absolute;
    background: white;
    overflow-y: auto;
    top: 28px;
    left: 100%;

    width: 359px;
    bottom: 28px;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    border: 1px solid #efefef;

    text-align: left;

  }*/
.sidemenu__sub-items-container .group__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -1px;
    position: relative;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem,
.sidemenu__top-level-item>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    width: 84px
        /* 84px */
    ;
    height: 80px;
    position: relative;
    margin-right: -1px;
    margin-bottom: -1px;
    cursor: pointer;
}

.sidemenu__subitem {
    width: 120px !important;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem:hover {
    background: #f9f9f9;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem img,
.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    width: 28px;
    margin-bottom: 20px;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    margin-bottom: 20px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem .subitem__text,
/* .sidemenu__top-level-item .sidemenu__top-level-item__text {
    position: absolute;
    top: 48px;
    max-width: 80px;
    font-size: 10px;
    line-height: 1.05em;
    font-weight: 500;
    color: #000000;
    font-weight: bold;
} */


.sidemenu__top-level-item .sidemenu__top-level-item__text {
    position: absolute;
    top: 48px;
    max-width: 100px;
    font-size: 9px;
    line-height: 1.05em;
    font-weight: 500;
    color: #000000;
    font-weight: bold;
    min-width: 100px;
}



.sidemenu__top-level-item .sidemenu__top-level-item__text {
    top: 50px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    line-height: 1.1em;
    font-weight: 600;
    font-size: 9px;
}

.sidemenu__sub-items-container .group__text {
    background-color: #f9f9f9;
    font-weight: 700;
    padding: 20px 16px 6px 16px;
    padding: 1px;

    font-size: 12px;
    color: rgba(0, 0, 0, 0.78);
    letter-spacing: 0px;
    border-bottom: 2px solid #f3f3f3;
}

.sidemenu__sub-items-container.hidden {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.sidemenu__sub-items-container {
    z-index: 1;

    position: absolute;
    position: fixed;
    background: white;
    overflow-y: auto;
    width: 100vw;
    bottom: 0px;
    /*border: 1px black solid;*/
    /* height: 100vh; */
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-content: inherit;


    /* justify-content: flex-end; */
    justify-content: start;

    bottom: 56px;

    max-height: calc(100vh - 120px);

}

.sidemenu__group-container ul {
    padding-left: 0px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container img {
    max-width: 26px;
}

.v-image__image.v-image__image--contain {
    transform: translateY(-6px);
}

.sidemenu__group-container .v-image.v-responsive.theme--light {
    margin-bottom: 22px;
}

@media screen and (max-width: 768px) {

    .sidemenu__sub-items-container {
        justify-content: start;
        display: flex;
        max-height: calc(100vh - 120px);
    }
}
</style>

<style>
.clRowSectionMenu {
    display: flex;
    justify-items: center;
    height: 40px;
    padding-top: 7px !important;
    border-color: #f9f9f9;
}

.v-input--switch__track {
    border-radius: 3px !important;
    width: 35px !important;
    height: 7px !important;
    top: 10px !important;
    left: 6px !important;
}

.v-input--switch__thumb {
    left: 6px !important;
    height: 15px !important;
    width: 15px !important;
    top: 5px !important;
}

.custom-switch .v-input__slot .v-label {
    left: 6px !important
}

.v-input--selection-controls__ripple {
    height: 0 !important;
    width: 0 !important;
}
</style>