<template>
    <v-container class="w-container-edit-group v-overflow edit_permissions_su" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">


                <div class="mainTitle" style="
                background-color: white;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#6cc115">{{ titolo }}</div>

            </v-col>

        </v-row>



        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <v-row style="margin-top:5px!important;padding-top: 0px!important;">

                <v-col cols="12" md="12" style="margin-top:0px!important;padding-top: 0px!important;">

                    <div class="clTitlePermissions">
                        E-Mail shared
                    </div>

                    <div>

                        <v-list style="overflow-y:auto;max-height: 80px;min-height: 80px;">

                            <v-list-tile v-for="el in email_shared" :key="el.email">

                                <div class="rowShared">

                                    <div class="colDescShared">
                                        <div class="selMailSU">{{ el.email }}</div>
                                    </div>

                                    <div class="colDeleteShared">

                                        <v-img alt="" contain src="@/assets/DOXWEB BOTTOM 49 cestino.png" max-width="20"
                                            max-height="20" style="cursor:pointer" transition="scale-transition"
                                            title="Delete" :data-id="el.email" :element-name="el.email"
                                            id="imgDelEMailSharedSU" />
                                    </div>

                                </div>


                            </v-list-tile>

                        </v-list>

                    </div>

                </v-col>

            </v-row>





            <v-row
                style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;">

                <v-col cols="12" md="12"
                    style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important">

                    <v-text-field v-model="mailSel" label="E-Mail" id="fldMail" class="clMail">
                    </v-text-field>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12" style="display:flex;justify-content: center;">

                    <v-img alt="" class="btn-cancel-otp-delete" contain src="@/assets/btn_confirm3_doxweb.png"
                        max-width="30" max-height="30" style="cursor:pointer;color:black" transition="scale-transition"
                        title="Confirm" @click="btnConfirmGroupOrMailSU" />

                </v-col>

            </v-row>

            <v-row v-if="viewPermissions">

                <v-col cols="12" md="12">

                    <div class="cntRi" style="margin-top:5px">

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_su" checked
                                disabled title="SU">
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:119px">
                            SU
                        </div>


                    </div>


                </v-col>

            </v-row>


            <v-row v-if="viewPermissions" style="padding-top:1px!important">

                <v-col cols="12" md="12" style="padding-top:1px!important">

                    <div class="clTitlePermissions">
                        Files
                    </div>

                    <div class="cntRi" style="margin-top:5px">

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_enter"
                                title="Can enter">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain
                                src="@/assets/DOXWEB BOTTOM 34 can enter copia.png" max-width="25" max-height="25"
                                transition="scale-transition" />
                        </div>

                        <div class="clColSpace">
                            Can enter
                        </div>

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_show"
                                title="Can view files">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/DOXWEB BOTTOM 33 view file.png"
                                max-width="25" max-height="25" transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px">
                            Can view
                        </div>

                    </div>

                    <div class="cntRi" style="margin-top:5px">

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_remove_file"
                                title="Can remove file">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/DOXWEB BOTTOM 35 remove file.png"
                                max-width="25" max-height="25" transition="scale-transition" />
                        </div>

                        <div class="clColSpace">
                            Can remove
                        </div>

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                id="p_rename_file_folder" title="Can rename files and folders">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/DOXWEB BOTTOM 36 rename.png"
                                max-width="25" max-height="25" transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px">
                            Can rename
                        </div>

                    </div>


                    <div class="cntRi" style="margin-top:5px">

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_otp_delete_file"
                                title="Request otp on delete">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/DOXWEB BOTTOM 35 remove otp.png"
                                max-width="25" max-height="25" transition="scale-transition" />
                        </div>

                        <div class="clColSpace">
                            OTP delete
                        </div>

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                id="p_download_and_delete_file" title="Delete file on download">
                        </div>

                        <div style="margin-left: 10px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/download_and_delete_doxweb.png"
                                max-width="15" max-height="15" transition="scale-transition" />
                        </div>

                        <div style="margin-left: 9px;font-weight: bold;font-size:9px">
                            Download & Delete
                        </div>

                    </div>


                    <div class="cntRi" style="margin-top:5px">

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                id="p_send_file_by_mail" title="Send by mail">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/send_file_by_mail.png"
                                max-width="25" max-height="25" transition="scale-transition" />
                        </div>

                        <div class="clColSpace">
                            Send by mail
                        </div>

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_expired_file"
                                title="File expiration">
                        </div>

                        <div style="margin-left: 10px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/expiring_file.png" max-width="15"
                                max-height="15" transition="scale-transition" />
                        </div>

                        <div style="margin-left: 9px;font-weight: bold;font-size:9px">
                            Expiration
                        </div>


                    </div>





                    <div class="clTitlePermissions" style="margin-top: 10px;">
                        Folders
                    </div>

                    <div class="cntRi" style="margin-top:5px">

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_remove_folder"
                                title="Can remove folder">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain
                                src="@/assets/DOXWEB BOTTOM 38 remove folder.png" max-width="25" max-height="25"
                                transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                            Can remove
                        </div>

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_foldersignature"
                                title="Can set folder for signatures">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain
                                src="@/assets/DOXWEB BOTTOM 40 folder signature.png" max-width="25" max-height="25"
                                transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                            Can set signatures
                        </div>


                    </div>


                    <div class="cntRi" style="margin-top:5px">


                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_upload"
                                title="Can upload">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/DOXWEB BOTTOM 41 can upload.png"
                                max-width="25" max-height="25" transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                            Can upload
                        </div>

                        <div>
                            <input class="no145" data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                id="p_worker" title="Folder for workers">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain
                                src="@/assets/DOXWEB BOTTOM 42 folder workers.png" max-width="25" max-height="25"
                                transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                            For workers
                        </div>

                    </div>


                    <div class="cntRi" style="margin-top:5px">


                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_email"
                                title="[EMAILS]if enabled, it notifies the users involved">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/DOXWEB BOTTOM 43 email alert.png"
                                max-width="25" max-height="25" transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                            EMails alert
                        </div>

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_copy"
                                title="To enable copy permissions">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain
                                src="@/assets/DOXWEB BOTTOM 44 copy permission.png" max-width="25" max-height="25"
                                transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                            Copy permissions
                        </div>

                    </div>

                    <div class="cntRi" style="margin-top:5px">



                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                id="p_otp_delete_folder" title="Request otp on delete">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/DOXWEB BOTTOM 35 remove otp.png"
                                max-width="25" max-height="25" transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                            OTP delete
                        </div>

                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_create_folder"
                                title="Request otp on delete">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/doxweb_create_folder.png"
                                max-width="25" max-height="25" transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px;width: 140px;">
                            Can create
                        </div>


                    </div>


                    <div class="cntRi" style="margin-top:5px">



                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_edit_permissions"
                                title="Request otp on delete">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/edit_permissions_doxweb.png"
                                max-width="25" max-height="25" transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                            Edit permissions
                        </div>


                        <div>
                            <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_zip_folder"
                                title="Zip folder">
                        </div>

                        <div style="margin-left: 5px;">
                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/zip_folder_doxweb.png"
                                max-width="25" max-height="25" transition="scale-transition" />
                        </div>

                        <div style="margin-left: 5px;font-weight: bold;font-size:9px;width: 140px;">
                            Can zip
                        </div>

                    </div>


                </v-col>

            </v-row>


        </v-form>



    </v-container>
</template>

<script>
import apipianoorario from "../utils/pianoorario/apipianoorario";
import apigroups from "../utils/groups/apigroups";

import {
    bus
} from "../main";

import router from ".././router";
import apidoxweb from "../utils/doxweb/apidoxweb";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;


        this.cur_id = this.$route.params.id;

        this.setupButtons(false);

        setTimeout(() => {

            this.getEMailsSU();

        }, 100);



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.btnConfirmPermissionsSU();

                    break;

                case "btn_back":
                    router.push({
                        path: "/groups"
                    });
                    break;

                case "btn_utenti":

                    window.$cookies.set("sel_filter_azienda_gruppo_utenti", $("#companyid").val(), "9y");
                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupSelUsers"
                    });


                    break;

                case "btn_mail":

                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupMails"
                    });

                    break;


                default:
                    break;
            }
        });

        $(document).ready(function () {


            $('body').on('click', '#imgDelEMailSharedSU', function () {

                var v_email = $(this).attr("data-id");

                pointerVue.deleteElementSharedSU(v_email);

            });


            $('body').on('click', '.selMailSU', function () {


                console.log("MAIL: ", $(this).html());

                pointerVue.valueSelected = "E";

                pointerVue.enableDisableSection();

                pointerVue.mailSel = $(this).html();

                pointerVue.btnConfirmGroupOrMailSU();

            });




            $('body').on('input', '#fldMail', function () {


                console.log("FLDMAIL INPUT EVENT");

                pointerVue.setupButtons(false);

                pointerVue.viewPermissions = false;
                pointerVue.viewSpace = true;


            });





            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetAziende = true;

                    setTimeout(() => {

                        $("#fldTextSearchAzi").focus();

                    }, 500);

                }

            });

            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });

            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');

                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);

            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });

            $(".lunedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".martedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".mercoledi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".giovedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".venerdi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".sabato").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".domenica").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

        });

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({

        email_shared: [],

        viewPermissions: false,

        mailSel: "",


        dox_server: { server_name: "", ip_server: "", path_private_key: "", key_name: "" },

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        valTotale: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,

        gruppo: {},

        dipendenti: [],

        dipartimentiTemp: [],

        scopesTemp: [],

        ore: [],

        currAzienda: "",
        titolo: "Permissions SU",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        // tipologie: [],

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],

    }),

    methods: {


        deleteElementSharedSU: async function (id_element) {

            var that = this;

            var msg_warning = "";


            msg_warning = "Are you sure you want to delete the sharing with the email address " + id_element + " ?";


            that.$swal
                .fire({
                    title: "Delete",
                    html: msg_warning,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Confirm' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_doxweb.png' />",
                    cancelButtonText: "<img title='Cancel' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_doxweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apidoxweb.deleteElementSharedSU(
                            id_element
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteElementSharedSU", res);

                            that.$swal({
                                icon: "success",
                                text: "Data deleted successfully",
                                showConfirmButton: false,
                                timer: 2000
                            });


                            setTimeout(() => {

                                var email_shared_new = [];

                                for (var x = 0; x < that.email_shared.length; x++) {

                                    if (that.email_shared[x].email != id_element) {

                                        email_shared_new.push({
                                            email: that.email_shared[x].email
                                        });

                                    }


                                }


                                that.email_shared = email_shared_new;


                                that.mailSel = "";
                                that.viewMail = false;
                                that.viewPermissions = false;

                                this.setupButtons(false);


                            }, 2000);







                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },




        getEMailsSU: async function () {


            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.getEMailsSU(
                0
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getEMailsSU", res);


                //that.email_shared = res.data.Result;

                that.email_shared = [];


                if (res.data.Result != null) {

                    for (var x = 0; x < res.data.Result.length; x++) {


                        if (res.data.Result[x].email != null) {

                            that.email_shared.push({
                                email: res.data.Result[x].email
                            });

                        }

                    }


                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );







        },






        btnConfirmPermissionsSU: async function () {


            var that = this;


            var dest = "";

            dest = that.mailSel;



            var v_p_enter = 0;
            if ($('#p_enter').prop('checked')) {
                v_p_enter = 1;
            }


            var v_p_show = 0;
            if ($('#p_show').prop('checked')) {
                v_p_show = 1;
            }

            var v_p_remove_file = 0;
            if ($('#p_remove_file').prop('checked')) {
                v_p_remove_file = 1;
            }


            var v_p_rename_file_folder = 0;
            if ($('#p_rename_file_folder').prop('checked')) {
                v_p_rename_file_folder = 1;
            }

            var v_p_remove_folder = 0;
            if ($('#p_remove_folder').prop('checked')) {
                v_p_remove_folder = 1;
            }


            var v_p_set_password = 0;


            var v_p_foldersignature = 0;
            if ($('#p_foldersignature').prop('checked')) {
                v_p_foldersignature = 1;
            }

            var v_p_upload = 0;
            if ($('#p_upload').prop('checked')) {
                v_p_upload = 1;
            }


            var v_p_worker = 0;
            if ($('#p_worker').prop('checked')) {
                v_p_worker = 1;
            }

            var v_p_email = 0;
            if ($('#p_email').prop('checked')) {
                v_p_email = 1;
            }


            var v_p_copy = 0;
            if ($('#p_copy').prop('checked')) {
                v_p_copy = 1;
            }


            var v_p_otp_delete_file = 0;
            if ($('#p_otp_delete_file').prop('checked')) {
                v_p_otp_delete_file = 1;
            }


            var v_p_otp_delete_folder = 0;
            if ($('#p_otp_delete_folder').prop('checked')) {
                v_p_otp_delete_folder = 1;
            }

            var v_p_create_folder = 0;
            if ($('#p_create_folder').prop('checked')) {
                v_p_create_folder = 1;
            }

            var v_p_edit_permissions = 0;
            if ($('#p_edit_permissions').prop('checked')) {
                v_p_edit_permissions = 1;
            }

            var v_p_download_and_delete_file = 0;
            if ($('#p_download_and_delete_file').prop('checked')) {
                v_p_download_and_delete_file = 1;
            }

            var v_p_zip_folder = 0;
            if ($('#p_zip_folder').prop('checked')) {
                v_p_zip_folder = 1;
            }

            var v_p_send_file_by_mail = 0;
            if ($('#p_send_file_by_mail').prop('checked')) {
                v_p_send_file_by_mail = 1;
            }

            var v_p_expired_file = 0;
            if ($('#p_expired_file').prop('checked')) {
                v_p_expired_file = 1;
            }




            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.savePermissionsSU(
                dest,
                "p_enter",
                1,
                v_p_enter,
                v_p_show,
                v_p_remove_file,
                v_p_rename_file_folder,
                v_p_remove_folder,
                v_p_set_password,
                v_p_foldersignature,
                v_p_upload,
                v_p_worker,
                v_p_email,
                v_p_copy,
                v_p_otp_delete_file,
                v_p_otp_delete_folder,
                v_p_create_folder,
                v_p_edit_permissions,
                v_p_download_and_delete_file,
                v_p_zip_folder,
                v_p_send_file_by_mail,
                v_p_expired_file
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from savePermissionsSU", res);

                that.$swal({
                    icon: "success",
                    text: "Data saved successfully",
                    showConfirmButton: false,
                    timer: 2000
                });




                setTimeout(() => {

                    that.mailSel = "";
                    that.viewMail = false;
                    that.viewPermissions = false;

                    that.setupButtons(false);

                    that.getEMailsSU();

                }, 2000);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );







        },



        loadPermissionsMailSU: async function () {

            var that = this;

            window.oldSetPassword = 0;

            var dest = that.mailSel;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.loadPermissionsSU(
                dest,
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from loadPermissionsSU", res);


                if (res.data.isMailSU == "S") {


                    that.viewSpace = false;
                    that.viewPermissions = true;


                    setTimeout(() => {

                        $(".btn-confirm-permissions").show();


                        $("#p_enter").prop("checked", false);
                        $("#p_show").prop("checked", false);
                        $("#p_remove_file").prop("checked", false);
                        $("#p_rename_file_folder").prop("checked", false);
                        $("#p_remove_folder").prop("checked", false);
                        $("#p_set_password").prop("checked", false);
                        $("#p_foldersignature").prop("checked", false);
                        $("#p_upload").prop("checked", false);
                        $("#p_worker").prop("checked", false);
                        $("#p_email").prop("checked", false);
                        $("#p_copy").prop("checked", false);
                        $("#p_otp_delete_file").prop("checked", false);
                        $("#p_otp_delete_folder").prop("checked", false);
                        $("#p_create_folder").prop("checked", false);
                        $("#p_edit_permissions").prop("checked", false);
                        $("#p_download_and_delete_file").prop("checked", false);
                        $("#p_zip_folder").prop("checked", false);
                        $("#p_send_file_by_mail").prop("checked", false);
                        $("#p_expired_file").prop("checked", false);

                        if (res.data.QueryPerm != null) {

                            console.log("ok");

                            window.oldSetPassword = res.data.QueryPerm.p_set_password;

                            if (window.oldSetPassword == null) {
                                window.oldSetPassword = 0;
                            }

                            console.log("ok singleshare");

                            if (res.data.QueryPerm.p_enter == 1) {

                                console.log("ok p_enter");


                                $("#p_enter").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_show == 1) {

                                $("#p_show").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_remove_file == 1) {

                                $("#p_remove_file").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_rename_file_folder == 1) {

                                $("#p_rename_file_folder").prop("checked", true);

                            }


                            if (res.data.QueryPerm.p_remove_folder == 1) {

                                $("#p_remove_folder").prop("checked", true);

                            }


                            if (res.data.QueryPerm.p_set_password == 1) {

                                $("#p_set_password").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_foldersignature == 1) {

                                $("#p_foldersignature").prop("checked", true);

                            }


                            if (res.data.QueryPerm.p_upload == 1) {

                                $("#p_upload").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_worker == 1) {

                                $("#p_worker").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_email == 1) {

                                $("#p_email").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_copy == 1) {

                                $("#p_copy").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_otp_delete_file == 1) {

                                $("#p_otp_delete_file").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_otp_delete_folder == 1) {

                                $("#p_otp_delete_folder").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_create_folder == 1) {

                                $("#p_create_folder").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_edit_permissions == 1) {

                                $("#p_edit_permissions").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_download_and_delete_file == 1) {

                                $("#p_download_and_delete_file").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_zip_folder == 1) {

                                $("#p_zip_folder").prop("checked", true);

                            }

                            if (res.data.QueryPerm.p_send_file_by_mail == 1) {

                                $("#p_send_file_by_mail").prop("checked", true);

                            }


                            if (res.data.QueryPerm.p_expired_file == 1) {

                                $("#p_expired_file").prop("checked", true);

                            }




                        }




                        that.setupButtons(true);



                    }, 200);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: "Email not from a SU user",
                        showConfirmButton: false,
                        timer: 3000
                    });

                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },


        validateEMail: function (v_email) {

            var result;

            // eslint-disable-next-line
            var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

            console.log("MAIL: ", v_email);

            if (v_email != undefined) {

                if (v_email != "") {

                    if (v_email.toString().match(validRegex)) {
                        result = true;
                    }
                    else {
                        result = false;

                    }

                }
                else {
                    result = false;


                }


            }
            else {
                result = true;

            }


            return result;

        },

        enableDisableSection: function () {

            this.setupButtons(false);

            console.log("CHANGE: ", this.valueSelected);

            this.mailSel = "";
            this.viewMail = true;
            this.viewPermissions = false;


        },


        btnConfirmGroupOrMailSU: function () {

            var errore = "";


            if (this.mailSel == "") {

                errore = "E-Mail required";

            }



            if (errore == "") {


                if (!this.validateEMail(this.mailSel)) {

                    errore = "E-Mail not valid";

                }



            }






            if (errore == "") {



                this.loadPermissionsMailSU();


            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }




        },




        updateTotal() {

            console.log("LUNEDI: ", $(".lunedi").val());

            var v_lunedi = 0;
            if ($(".lunedi").val() != "") {
                v_lunedi = $(".lunedi").val();
            }

            var v_martedi = 0;
            if ($(".martedi").val() != "") {
                v_martedi = $(".martedi").val();
            }

            var v_mercoledi = 0;
            if ($(".mercoledi").val() != "") {
                v_mercoledi = $(".mercoledi").val();
            }

            var v_giovedi = 0;
            if ($(".giovedi").val() != "") {
                v_giovedi = $(".giovedi").val();
            }

            var v_venerdi = 0;
            if ($(".venerdi").val() != "") {
                v_venerdi = $(".venerdi").val();
            }

            var v_sabato = 0;
            if ($(".sabato").val() != "") {
                v_sabato = $(".sabato").val();
            }

            var v_domenica = 0;
            if ($(".domenica").val() != "") {
                v_domenica = $(".domenica").val();
            }

            var v_tot = parseFloat(v_lunedi) + parseFloat(v_martedi) + parseFloat(v_mercoledi) + parseFloat(v_giovedi) + parseFloat(v_venerdi) + parseFloat(v_sabato) + parseFloat(v_domenica);

            console.log("TOT: ", v_tot);

            this.valTotale = v_tot.toFixed(2);

        },

        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        setDipartimenti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.dipartimentiTemp);

            var dipartimentiAzienda = [];

            for (var i = 0; i < this.dipartimentiTemp.length; i++) {

                if (parseInt(this.dipartimentiTemp[i].companyid) == parseInt(id_azienda)) {

                    dipartimentiAzienda.push({
                        departmentid: this.dipartimentiTemp[i].departmentid,
                        fld_name: this.dipartimentiTemp[i].fld_name
                    });

                }

            }

            console.log("DIP SEL: ", dipartimentiAzienda);

            this.dipartimenti = dipartimentiAzienda;

        },

        setScopes: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("SCOPES TMP: ", this.scopesTemp);

            var scopesAzienda = [];

            for (var i = 0; i < this.scopesTemp.length; i++) {

                if (parseInt(this.scopesTemp[i].company_id) == parseInt(id_azienda)) {

                    scopesAzienda.push({
                        id: this.scopesTemp[i].id,
                        name: this.scopesTemp[i].name
                    });

                }

            }

            console.log("SCOPES SEL: ", scopesAzienda);

            this.scopes = scopesAzienda;

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.gruppo.companyid = azienda.companyid;

            this.setDipartimenti(azienda.companyid);

            this.setScopes(azienda.companyid);


        },

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },

        setupButtons: async function (view_save) {

            setTimeout(() => {

                console.log(view_save);

                var pulsantis = [];

                if (view_save) {

                    pulsantis.push({
                        text: "Save",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: this.getUrlDomain("public/_lib/img/icon_save_doxweb.png"),
                        title: "Save",
                        width: 30
                    });


                }





                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);





        },

        validate() {

            var that = this;

            var errore = "";


            if (that.dox_server.server_name == "") {
                errore = "Server name required";
            }


            if (errore == "") {

                if (that.dox_server.ip_server == "") {
                    errore = "IP server required";
                }

            }

            if (errore == "") {

                if (that.dox_server.path_private_key == "") {
                    errore = "Path private key required";
                }

            }


            if (errore == "") {

                if (that.dox_server.key_name == "") {
                    errore = "Name key required";
                }

            }

            if (errore == "") {

                console.log("ok");

                that.saveData();

            }

            if (errore != "") {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }




        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        initInsertGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apigroups.initInsert(
                v_token,
                "emt"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);

                this.aziende = res.data.aziende;

                this.dipartimentiTemp = res.data.dipartimenti;

                this.scopesTemp = res.data.scope;


                // imposto di default l'azienda del filtro
                var v_company = window.$cookies.get("sel_filter_azienda_gruppi");

                that.gruppo.companyid = v_company;

                this.setDipartimenti(v_company);

                this.setScopes(v_company);


                $("#itemCompany").addClass("item-has-value");



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



        initEditGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apigroups.initEdit(
                that.$route.params.id,
                v_token,
                "emt"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initEditGruppo", res);

                this.aziende = res.data.aziende;

                this.dipartimentiTemp = res.data.dipartimenti;

                console.log("COMPANYID: ", res.data.gruppo.companyid);

                this.scopesTemp = res.data.scope;

                this.setDipartimenti(res.data.gruppo.companyid);

                this.setScopes(res.data.gruppo.companyid);

                this.gruppo = res.data.gruppo;


                if (res.data.gruppo.companyid != null) {

                    $("#itemCompany").addClass("item-has-value");

                }

                if (res.data.gruppo.departmentid != null) {

                    $("#itemDipartimento").addClass("item-has-value");

                }

                if (res.data.gruppo.group_scope_id != null) {

                    $("#itemScope").addClass("item-has-value");

                }


                var is_load_users_from_group = window.$cookies.get("is_load_users_from_group");

                if (is_load_users_from_group == 1) {

                    if (res.data.recipients != null) {

                        window.$cookies.set("sel_id_users", res.data.recipients, "9y");

                    }

                    if (res.data.mails != null) {

                        window.$cookies.set("sel_mails", res.data.mails, "9y");

                    }

                    window.$cookies.set("is_load_users_from_group", 0, "9y");
                }




                setTimeout(() => {

                    window.$cookies.set("nome_gruppo_edit", that.gruppo.name, "9y");

                    var v_scope_name = "";

                    for (var x = 0; x <= this.scopesTemp.length - 1; x++) {

                        if (parseInt(this.scopesTemp[x].id) == that.gruppo.group_scope_id) {

                            v_scope_name = this.scopesTemp[x].name;

                        }

                    }

                    window.$cookies.set("nome_scope_gruppo_edit", v_scope_name, "9y");

                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },




        syncPianoOrario: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianoorario.getPianoOrario(
                id_rec,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPianoOrario", res);

                this.aziende = res.data.company;

                this.ore = res.data.ore;

                this.pianoorario = res.data.piano_orario;

                $("#itemCompany").addClass("item-has-value");

                if (this.pianoorario.lunedi != null) {

                    $("#itemLunedi").addClass("item-has-value");

                }

                if (this.pianoorario.martedi != null) {

                    $("#itemMartedi").addClass("item-has-value");

                }

                if (this.pianoorario.mercoledi != null) {

                    $("#itemMercoledi").addClass("item-has-value");

                }

                if (this.pianoorario.giovedi != null) {

                    $("#itemGiovedi").addClass("item-has-value");

                }

                if (this.pianoorario.venerdi != null) {

                    $("#itemVenerdi").addClass("item-has-value");

                }

                if (this.pianoorario.sabato != null) {

                    $("#itemSabato").addClass("item-has-value");

                }

                if (this.pianoorario.domenica != null) {

                    $("#itemDomenica").addClass("item-has-value");

                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        editConfigServer: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.editConfigServer(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editConfigServer", res);

                that.dox_server = res.data.Result;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        saveData: async function () {

            var that = this;

            var response = await apidoxweb.saveConfigServer(
                that.dox_server
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveConfigServer", res);

                that.$swal({
                    icon: "success",
                    text: "Data saved successfully",
                    showConfirmButton: false,
                    timer: 2000
                });

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamento(
                that.$route.params.id, v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamento", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;

                    if (res.data.Result.is_child_pag == "N") // pagamento master
                    {

                        this.pagamento.importo_pagato = null;

                        var today = new Date();

                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);

                        console.log("DATE: ", sDate);

                        this.pagamento.data_pagamento = sDate; // default data corrente

                    }

                    if (res.data.Result.is_child_pag == "S") // pagamento child
                    {

                        this.disField = true;

                    }

                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");

                    }

                    this.valoreDaPagare = res.data.Result.importo;

                    this.valoreRimanente = res.data.Result.importo_rimanente;

                    this.disFieldRim = true;

                    var allegato_salvato = res.data.Result.allegato_salvato;

                    if (allegato_salvato) {

                        var elements = allegato_salvato.split(".");

                        console.log("ELEMENTS: ", elements);

                        var txt_image = "";
                        if (elements[1] == "pdf") {

                            txt_image = this.getUrlDomain("public/documents/pdf_extension.png");

                        } else {

                            txt_image = this.getUrlDomain("public/documents/" + allegato_salvato);

                        }

                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.clColSpace {
    margin-left: 5px;
    font-weight: bold;
    font-size: 9px;
    width: 140px;
}


.edit_permissions_su .colDeleteShared {}


.edit_permissions_su .selMailSU {
    cursor: pointer;
    width: 130px;
}



.clMail {

    padding-bottom: 0px !important;
    height: 30px !important;
    padding-top: 0px !important;
    margin-top: -18px !important;

}

.cntRi {
    align-items: center;
    display: flex;
    flex-direction: row;
    min-height: 23px;
    margin-left: 10px;
}

.clTitlePermissions {
    min-width: 300px;
    color: white;
    padding: 3px;
    background: rgb(157, 155, 155);
    font-weight: bold;
    font-size: 13px;
    margin-left: 10px;
    margin-right: 10px;
}

.w-container-edit-group {
    width: 500px;
    padding: 0px;
    z-index: 3;
    overflow-x: hidden !important;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-edit-group {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

    .edit_permissions_su .colDescShared {
        margin-left: 10px !important;
        width: 325px !important;
    }

    .clColSpace {
        margin-left: 5px;
        font-weight: bold;
        font-size: 9px;
        width: 130px;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}


.edit_permissions_su .rowShared {
    display: flex;
    align-items: center;
    height: 22px;
    margin-bottom: 3px;
}

.edit_permissions_su .colDescShared {
    margin-left: 10px;
    width: 453px;
}

.edit_permissions_su .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.edit_permissions_su .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.edit_permissions_su ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.w-container-edit-group .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}
</style>
