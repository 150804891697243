<template>

    <div class="mainWrap">
        <frontslider table="emt_settings" app="emt" ref="slidercomp" style="display:block;"></frontslider>


        <div id="logins">

            <link href="https://fonts.googleapis.com/css?family=Roboto:100,400,800" rel="stylesheet" />

            <div class="fs-container">
                <div class="fs-container__bg"></div>
                <div class="content">
                    <div class="content-login">
                        <div class="content__pane init2">

                            <img v-if="showEmt" class="logo" src="@/assets/emt-logo-small.png"
                                style="max-width: 60px" />
                            <img v-if="showNext3" class="logo" src="@/assets/next3-logo-small.png"
                                style="max-width: 60px" />

                            <form name="login" id="login-form" novalidate="" method="POST" v-on:submit.prevent
                                style="max-width: 286px;">
                                <div class="flex-container">
                                    <input type="hidden" id="t_login" name="t_login" value="NO_QRCODE" />

                                    <div id="classic-login">
                                        <div class="group filled">
                                            <input v-model="input.username" id="user" class="user" required=""
                                                name="user" type="text" value="" />

                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>
                                                <img src="@/assets/loginstart/img/user.png" />
                                                <span class="label__text label__text--hidden">Username</span>
                                            </label>
                                        </div>

                                        <div class="group filled" style="margin-bottom: 0px">
                                            <input ref="currPass" v-model="input.password" id="password"
                                                class="password" required="" name="password" type="password" value="" />
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <img @click="showPassword" class="showpwd"
                                                src="@/assets/icons8-eye-24.png" />

                                            <label>
                                                <span class="label__text label__text--hidden">Password</span>
                                                <img src="@/assets/loginstart/img/pwd.png" />
                                            </label>

                                            <a id="forgotPass" @click="recover">
                                                Forgot password?
                                            </a>





                                            <!-- <router-link id="register" tag="a" :to="'/register'"> Register</router-link> -->

                                        </div>
                                    </div>

                                    <div id="qr-code-wrapper">
                                        <button type="button" id="qr-code-btn">
                                            <span class="tooltiptext">Login with QR-Code</span>

                                            <img src="@/assets/loginstart/img/qr-btn-alpha.png" alt="submit" />
                                        </button>
                                    </div>

                                    <!-- added code -->

                                    <div class="container" id="qr-login" style="display: none">
                                        <div>
                                            <a class="button" id="resetButton">Back</a>
                                        </div>

                                        <div>
                                            <video id="video" width="300" height="200"
                                                style="border: 1px solid gray"></video>
                                        </div>

                                        <div id="sourceSelectPanel" style="display: none">
                                            <label for="sourceSelect">Change video source:</label>
                                            <select id="sourceSelect" style="max-width: 400px"></select>
                                        </div>

                                        <div style="text-align: left; display: none">
                                            <label>Result:</label>
                                            <pre><code id="result"></code></pre>
                                        </div>
                                    </div>

                                    <!-- end added code -->
                                </div>

                                <div id="lang-select" class="group select">
                                    <div class="lang__field">
                                        <span class="lang__selected"><img src="@/assets/loginstart/img/uk.png"
                                                class="flag-img" /></span>
                                        <label class="select__label">
                                            <img src="@/assets/loginstart/img/language.png" />
                                        </label>
                                        <div class="lang__options">
                                            <div data-value="EN" class="lang__option">
                                                <img src="@/assets/loginstart/img/uk.png" class="flag-img" />
                                            </div>
                                            <div data-value="IT" class="lang__option">
                                                <img src="@/assets/loginstart/img/italy.png" class="flag-img" />
                                            </div>
                                        </div>
                                    </div>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                </div>

                                <div id="otp-select" class="group select">
                                    <div class="otp__field">
                                        <span class="otp__selected">
                                            <div class="otp-text">Email</div>
                                            <img src="@/assets/loginstart/img/mail.png" class="otp-img" />
                                        </span>
                                        <label class="select__label">
                                            <div class="text-label">OTP</div>
                                            <img src="@/assets/loginstart/img/token.png" />
                                        </label>
                                        <div class="otp__options">
                                            <div data-value="MAIL" class="otp__option">
                                                <div class="otp-text">Email</div>
                                                <img src="@/assets/loginstart/img/mail.png" class="otp-img" />
                                            </div>
                                            <div data-value="SMS" class="otp__option">
                                                <div class="otp-text">Sms</div>
                                                <img src="@/assets/loginstart/img/sms.png" class="otp-img" />
                                            </div>

                                            <div data-value="Auth" class="otp__option">
                                                <div class="otp-text">Auth</div>
                                                <img src="@/assets/loginstart/img/auth.png" class="otp-img" />
                                            </div>
                                        </div>
                                    </div>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                </div>

                                <button class="
                  button button--block button--outline button--primary
                  modal-trigger
                " id="login-button" value="login" v-on:click="login()">
                                    <div class="button--text">Login</div>
                                    <div class="spinner hidden">
                                        <div class="holder">
                                            <div class="preloader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </button>

                                <rise-loader v-if="showSpinner" :size="'20px'" :color="'#6cc115'" />
                                <!-- <div class="info info--error info--hidden">
                Devi inserire sia il nome utente sia la password
              </div> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>
</template>

<style lang="css" scoped>
@import "./../assets/loginstart/style.css";
</style>

<script>
//import fslider from "../../../vuecommon/background.vue"

import frontslider from "../../../vuecommon/frontslider";


//import $ from 'jquery';
import RiseLoader from "vue-spinner/src/RiseLoader.vue";

import router from ".././router";

/* import Vue from 'vue' */
//import App from '.././App.vue'

export default {
    components: {
        /* PulseLoader, */
        RiseLoader, frontslider
    },

    updated() { },

    mountedOLD: function () {
        console.log(this.$route.path);
        if (this.$route.path == "/") {
            // console.log( this.$parent );
            //  window.App.ok = 1 ;
            //   App.hideToolbars = true ;
            // Vue.set(this.$root , 'hideToolbars', true)
        } else {
            // window.App.ok = 0 ;
            //  App.hideToolbars = false ;
            // this.$root.hideToolbars = false ;
            // Vue.set(this.$root , 'hideToolbars', false)
        }
    },
    mounted: function () {

        var v_link = window.$cookies.get("page_reload");

        router.push({
            path: v_link
        });

    },
    name: "Login",
    data() {
        return {
            dialogRegister: true,

            showEmt: true,
            showNext3: false,

            notifications: false,
            sound: true,
            widgets: false,

            showSpinner: false,
            input: {
                username: "",
                password: "",
            },
        };
    },
    methods: {

        showPassword() {

            var currPass = this.$refs.currPass;
            if (currPass.type === "password") {
                currPass.type = "text"
                currPass.btnText = "Hide Password"
            } else {
                currPass.type = "password"
                currPass.btnText = "Show Password"
            }
        },


        getCookie() {
            this.$cookies.set("cookie-consent-performance", "yes");

            //  this.$swal('You are logged in!!!');

            //   console.log(Vue.cookie.get('token'));

            // it gets the cookie called `username`
            /* const username = this.$cookies.get("username");
            console.log(username); */
        },


        login() {
            this.showSpinner = true;
            this.getCookie();
            /*     if (this.input.username != "" && this.input.password != "") {
              if (
                this.input.username == this.$parent.mockAccount.username &&
                this.input.password == this.$parent.mockAccount.password
              ) {
                this.$emit("authenticated", true);
                this.$router.replace({ name: "secure" });
              } else {
                console.log("The username and / or password is incorrect");
              }
            } else {
              console.log("A username and password must be present");
            } */
        },
    },
};
</script>

<style scoped>
#qr-code-wrapper,
#lang-select,
#otp-select {
    display: none;
}

#login {
    width: auto;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    margin: auto;
    /*   margin-top: 200px; */
    padding: 20px;
}

#nav,
.v-sheet {
    display: none;
}

img.showpwd {
    position: absolute;
    /* right: 0px; */
    top: 14px;
    right: 0px;
    max-width: 22px;
    cursor: pointer;

}
</style>
