<template>
    <v-container class="w-container-edit-conf-dboost edit_conf_dboost"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">



        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;">


                <div class="mainTitle" style="
                            background-color: white!important;
                            color: black;
                            font-weight: bold;
                            padding: 4px;
                            font-size: 10px;                
                            min-width:100vw;
                            border-color:#6cc115;">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form v-model="valid" ref="form" lazy-validation>



            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <div id="cont_domainid" style="cursor:pointer">
                        <ion-item id="itemMetaTag"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                            <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Domain
                            </ion-label>

                            <ion-select id="id_sito" name="id_sito" class='id_sito' cancel-Text="Close" done-Text=""
                                :value="config_dboost.id_sito" interface="action-sheet">

                                <ion-select-option v-for="item in domains" :key="item.id" :value='item.id'> {{ item.url
                                    }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <div id="d_year"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;font-size:11px">Frequency
                                        (MM)</label>


                                    <vue-autonumeric id="valYear" v-model="config_dboost.minuti" :options="{
                                digitGroupSeparator: '',
                                decimalCharacter: ',',
                                decimalCharacterAlternative: '',
                                currencySymbol: '',
                                currencySymbolPlacement: 's',
                                roundingMethod: 'U',
                                minimumValue: '0',
                                decimalPlaces: '0'
                            }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="config_dboost.data_da" label="From" persistent-hint readonly
                                v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="config_dboost.data_da" no-title @input="showPicker = false"
                            locale="it"></v-date-picker>
                    </v-menu>
                    <div class="cl-del-date-from">
                        <img src="@/assets/elimina.png" id="btnDelDateFrom" title="Reset" />
                    </div>


                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <v-menu v-model="showPicker2" :close-on-content-click="false" transitions="scale-transition"
                        offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="config_dboost.data_a" label="To" persistent-hint readonly v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="config_dboost.data_a" no-title @input="showPicker2 = false"
                            locale="it"></v-date-picker>
                    </v-menu>
                    <div class="cl-del-date-to">
                        <img src="@/assets/elimina.png" id="btnDelDateTo" title="Reset" />
                    </div>


                </v-col>

            </v-row>



        </v-form>



    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apidomainboost from "../utils/domainboost/apidomainboost";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        var pointerVue = this;


        this.setupButtons();

        if (this.$route.params.id == 0) {


            this.initInsert();

        }


        if (this.$route.params.id > 0) {

            this.titolo = "Edit Configuration";

            this.syncConfig();

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/domainBoostConfiguration"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('ionChange', '.id_sito', function (event) {
                console.log(event);

                pointerVue.config_dboost.id_sito = this.value;


            });

            $('body').on('click', '.cl-del-date-from', function (event) {
                console.log(event);

                pointerVue.config_dboost.data_da = null;

            });

            $('body').on('click', '.cl-del-date-to', function (event) {
                console.log(event);

                pointerVue.config_dboost.data_a = null;

            });


        });

    },

    components: {
        VueAutonumeric,
    },



    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        domains: [],

        IsReadonly: false,

        valoresino: [{ id: "S", name: "Si" }, { id: "N", name: "No" }],

        changePsw: false,

        titolo: "Add Configuration",
        currDipendenteObj: {},

        gruppi_checked: [],

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker2: false,

        utente: { is_admin_company: "N", active: "Y" },

        config_dboost: { id_sito: "", minuti: "", data_da: "", data_a: "" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],


        urlRules: [
            (v) => !!v || "Field required",
            (v) => /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(v) || "Value not valid",
        ],

        mottoSiteRules: [
            (v) => !!v || "Field required",
        ],

        descriptionRules: [
            (v) => !!v || "Field required",
        ],



        wordRules: [
            (v) => !!v || "Field required",
        ],


        nameRules: [
            (v) => !!v || "Field required",
        ],


        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [

                    {
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: this.getUrlDomain("public/_lib/img/icon_save_dboost.png"),
                        title: "Save",
                        width: 30

                    },


                    {
                        text: "Indietro",
                        icon: "mdi-arrow-left",
                        image: this.getUrlDomain("public/_lib/img/back-dboost.png"),
                        link: "/tipologiapagamento",
                        id: "btn_back",
                        disabled: false,
                        title: "Back",
                        width: 35
                    },



                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;


            var errore = "";

            if (that.config_dboost.id_sito == "") {

                errore = "Domain required";
            }

            if (errore == "") {

                if (that.config_dboost.minuti == "") {

                    errore = "Frequency required";
                }

            }


            if (errore == "") {

                if (parseInt(that.config_dboost.minuti) < 15) {

                    errore = "Set a frequency of at least 15 minutes";
                }

            }



            if (errore == "") {


                if (that.config_dboost.data_da != "") {

                    if (that.config_dboost.data_a != "") {

                        if (that.config_dboost.data_da != null) {

                            if (that.config_dboost.data_a != null) {

                                var dt_start = new Date(that.config_dboost.data_da).getTime();
                                var dt_end = new Date(that.config_dboost.data_a).getTime();

                                if (dt_start > dt_end) {

                                    errore = "To must be greater than or equal to From";


                                }

                            }

                        }




                    }


                }


            }




            if (errore == "") {

                console.log("ok");

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }




        },

        saveData: async function () {

            var that = this;

            var response = null;

            if (that.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apidomainboost.insertConfig(
                    that.config_dboost
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertConfig", res);

                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Data saved successfully",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/domainBoostConfiguration"
                            });

                        }, 200);


                    }
                    else {


                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }


                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (that.$route.params.id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apidomainboost.updateConfig(
                    that.config_dboost,
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateConfig", res);

                    that.$swal({
                        icon: "success",
                        text: "Data saved successfully",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/domainBoostConfiguration"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        initInsert: async function () {

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidomainboost.initInsertConfig(
                0
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsertConfig", res);

                that.domains = res.data.Result;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncConfig: async function () {

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidomainboost.editConfig(
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editConfig", res);

                that.domains = res.data.Domains;

                setTimeout(() => {

                    that.config_dboost = res.data.Result;

                }, 200);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createUtenteApi", res);


                try {

                    that.aziende = res.data.aziende;

                    that.prefissi = res.data.prefix;

                    that.gruppi = res.data.models;


                    setTimeout(() => {

                        //that.utente.idcompany_logo = window.$cookies.get("sel_filter_azienda_utenti");
                        that.utente.idcompany_logo = window.$cookies.get("sel_filter_azienda_utenti");

                        $(".companyid").val(window.$cookies.get("sel_filter_azienda_utenti"));

                    }, 200);


                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.cl-del-date-from {
    width: 15px;
    cursor: pointer;
    transform: translate(452px, -54px);
}

.cl-del-date-to {
    width: 15px;
    cursor: pointer;
    transform: translate(452px, -54px);
}

.w-container-edit-conf-dboost .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}

.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-edit-conf-dboost {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.edit_conf_dboost .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}




.edit_conf_dboost .v-label {
    font-size: 11px !important;
}


.edit_conf_dboost .v-input {
    font-size: 11px !important;
    padding: 10px !important;
}

.edit_conf_dboost ion-select {
    font-size: 11px !important;
}



.edit_conf_dboost .cl-checkbox i {
    font-size: 13px !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .w-container-edit-conf-dboost {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .cl-del-date-from {
        width: 15px;
        cursor: pointer;
        transform: translate(377px, -54px);
    }

    .cl-del-date-to {
        width: 15px;
        cursor: pointer;
        transform: translate(377px, -54px);
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
