<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>


        <div class="row cont_doxweb">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>


                                        <!-- <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall'
                                                    style="width:10px!important;height:10px!important" class='checkall'
                                                    slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th> -->

                                        <th class='all'>&nbsp;</th>
                                        <th class='all'>N.</th>
                                        <th class='all' style="transform:translateX(1px)">Icon</th>
                                        <th class='all'>File / Folder</th>
                                        <th class='all'>Date</th>
                                        <th class='all' style="text-align:center;">Status</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="6">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/cerca-doxweb.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="" id="fldSearch" class="fieldRicerca">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupaddfolder" style="display:none">

            <template>
                <modal name="popupAddFolder" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Create New Folder

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="nameNewFolder" label="" id="fldNameNewFolder" class="fieldNameNewFolder">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelNewFolder" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmNewFolder" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopuprenamefolderfile" style="display:none">

            <template>
                <modal name="popupRenameFolderFile" :clickToClose="false" :width="312" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Rename "{{ nameCurrentElement }}"" to

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="nameNewElement" label="" id="fldNameNewElement"
                                class="fieldNameNewElement">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelRenameFolderFile" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmRenameFolderFile" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupuploadfile">

            <template>
                <modal name="popupUploadFile" :clickToClose="false" :width="386" :height="339">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:11px;margin-left:8px;font-weight: bold;">{{ titoloPopup }}</label>


                        </v-col>

                    </v-row>

                    <v-row class="rowPrevFile">

                        <v-col cols="12" md="12" style="margin-left: 12px;">


                            <span class="files">
                                <input type="file" id="input-file-attach" name="logo" :accept="fileAccettati"
                                    class="dropify" data-max-width="6610" data-max-height="6200" />
                            </span>


                        </v-col>

                    </v-row>


                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -23px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">File:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ nomeFilePresente }}</div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -7px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">Dim:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ dimFilePresente }}</div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload" contain src="@/assets/btn_cancel3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelUpload" />

                                <v-img alt="" class="btn-confirm-upload" contain src="@/assets/btn_confirm3_doxweb.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmUpload" />


                            </div>

                        </v-col>

                    </v-row>



                </modal>
            </template>

        </div>


        <div class="contpopupotpdelete" style="display:none">

            <template>
                <modal name="popupOtpDelete" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            OTP code sent to you via email

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="password" v-model="valueOtpDelete" label="" id="fldValueOtpDelete"
                                class="fieldValueOtpDelete">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain src="@/assets/btn_cancel3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelOtpDelete" />

                                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmOtpDelete" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopuppermissions" style="display:none">

            <template>
                <modal name="popupPermissions" :clickToClose="false" :width="344" :height="522">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;margin-left:10px;display:flex">

                            <!-- <div style="font-weight:bold;">Permissions</div> -->
                            <div style="font-weight:bold;margin-left: 5px;font-size:8.5px">{{ eleSelectedPermissions }}
                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;">

                        <v-col cols="12" md="12"
                            style="font-weight:bold;margin-left:10px;padding-top:0px!important;padding-bottom: 0px!important;">

                            <div class="flex-container">

                                <div class="flex-center">

                                    <v-radio-group v-model="valueSelected" row style="margin-top:0px!important">

                                        <v-radio label="Group" value="G" @click="chgRadio" color="red"></v-radio>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <v-radio label="E-Mail" value="E" @click="chgRadio" color="red"></v-radio>

                                    </v-radio-group>

                                </div>


                                <div v-if="viewButtonConfirm" class="clConfirmGroupOrMail">

                                    <v-img alt="" class="btn-cancel-otp-delete" contain
                                        src="@/assets/btn_confirm3_doxweb.png" max-width="30" max-height="30"
                                        style="cursor:pointer;color:black" transition="scale-transition" title="Annulla"
                                        @click="btnConfirmGroupOrMail" />

                                </div>

                            </div>

                        </v-col>

                    </v-row>

                    <v-row v-if="viewGroups"
                        style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;">

                        <v-col cols="12" md="12"
                            style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;">

                            <div id="cont_gruppo" style="cursor:pointer;margin-top:5px">
                                <ion-item style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px">

                                    <ion-label
                                        style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                        position="floating">Group </ion-label>

                                    <ion-select id="groupid" name="groupid" class='groupid' cancel-Text="Chiudi"
                                        done-Text="" :value="selGruppo" interface="action-sheet" disabled readonly>

                                        <ion-select-option v-for="item in gruppi" :key="item.idresources_group_name"
                                            :value='item.idresources_group_name'> {{
                                                item.full_name }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>


                        </v-col>

                    </v-row>

                    <v-row v-if="viewMail"
                        style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;">

                        <v-col cols="12" md="12"
                            style="padding-top:0px!important;padding-bottom: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important">

                            <v-text-field v-model="mailSel" label="E-Mail" id="fldMail" class="clMail">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row v-if="viewSpace">

                        <v-col cols="12" md="12">

                            <div style="height:288px">&nbsp;</div>

                        </v-col>

                    </v-row>

                    <v-row v-if="viewSpaceGroup">

                        <v-col cols="12" md="12">

                            <div style="height:274px">&nbsp;</div>

                        </v-col>

                    </v-row>

                    <v-row v-if="viewPermissions" style="padding-top:1px!important">

                        <v-col cols="12" md="12" style="padding-top:1px!important">

                            <div class="clTitlePermissions">
                                Files
                            </div>

                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_enter"
                                        title="Can enter">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 34 can enter copia.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    Can enter
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_show"
                                        title="Can view files">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 33 view file.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px">
                                    Can view
                                </div>

                            </div>

                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_remove_file"
                                        title="Can remove file">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 35 remove file.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    Can remove
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_rename_file_folder" title="Can rename files and folders">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain src="@/assets/DOXWEB BOTTOM 36 rename.png"
                                        max-width="25" max-height="25" transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px">
                                    Can rename
                                </div>

                            </div>


                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_otp_delete_file" title="Request otp on delete">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 35 remove otp.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    OTP delete
                                </div>

                            </div>

                            <div class="clTitlePermissions" style="margin-top: 10px;">
                                Folders
                            </div>

                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_remove_folder" title="Can remove folder">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 38 remove folder.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    Can remove
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_set_password"
                                        title="Can set password">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 39 set pass.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px">
                                    Can set password
                                </div>

                            </div>


                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_foldersignature" title="Can set folder for signatures">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 40 folder signature.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    Can set signatures
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_upload"
                                        title="Can upload">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 41 can upload.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px">
                                    Can upload
                                </div>

                            </div>


                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input class="no145" data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_worker" title="Folder for workers">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 42 folder workers.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    For workers
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_email"
                                        title="[EMAILS]if enabled, it notifies the users involved">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 43 email alert.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px">
                                    EMails alert
                                </div>

                            </div>

                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox" id="p_copy"
                                        title="To enable copy permissions">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 44 copy permission.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width:140px">
                                    Copy permissions
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_otp_delete_folder" title="Request otp on delete">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain
                                        src="@/assets/DOXWEB BOTTOM 35 remove otp.png" max-width="25" max-height="25"
                                        transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px">
                                    OTP delete
                                </div>


                            </div>


                            <div class="cntRi" style="margin-top:5px">

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_create_folder" title="Request otp on delete">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain src="@/assets/doxweb_create_folder.png"
                                        max-width="25" max-height="25" transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px;width: 140px;">
                                    Can create
                                </div>

                                <div>
                                    <input data-bs-toggle="tooltip" data-placement="top" type="checkbox"
                                        id="p_edit_permissions" title="Request otp on delete">
                                </div>

                                <div style="margin-left: 5px;">
                                    <v-img alt="Vuetify Logo" class="  " contain src="@/assets/edit_permissions_doxweb.png"
                                        max-width="25" max-height="25" transition="scale-transition" />
                                </div>

                                <div style="margin-left: 5px;font-weight: bold;font-size:9px">
                                    Edit permissions
                                </div>


                            </div>





                        </v-col>

                    </v-row>





                    <v-row style="padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="padding-top: 0px!important;">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain src="@/assets/btn_cancel3_doxweb.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black"
                                    transition="scale-transition" title="Annulla" @click="btnCancelPermissions" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>



        </div>



        <div class="contgruppi">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetGruppi" class="vaziendesheet" style="z-index:999999999999!important">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Gruppi
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetGruppi = !sheetGruppi">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field id="fldSearchGruppi" class="clSearchGruppi" hide-details
                                        prepend-icon="mdi-magnify" single-line v-model="searchGruppi">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredGruppi"
                                        :key="item.idresources_group_name" @click="manageClickGruppi(item)">

                                        <v-img alt="" src="@/assets/permissions_doweb.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.full_name }}</v-list-item-title>

                                    </v-list-item>


                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>




    </div>
</template>

<script>

import {
    bus
} from "../main";
/* import $ from 'jquery'; */

import apidoxweb from "../utils/doxweb/apidoxweb";
import apiotp from "../utils/otp/apiotp";


export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/


        var pointerVue = this;


        window.myVal = "";

        window.p_worker = window.$cookies.get("doxweb_p_worker");
        window.askFiles = window.$cookies.get("doxweb_askFiles");
        window.folder_level = window.$cookies.get("doxweb_folder_level");
        window.id = window.$cookies.get("doxweb_id");
        window.login = window.$cookies.get("doxweb_login");
        window.useremail = window.$cookies.get("doxweb_useremail");
        window.a = window.$cookies.get("doxweb_a");
        window.b = window.$cookies.get("doxweb_b");
        window.app = window.$cookies.get("doxweb_app");

        window.idFoldersNavigation = [];
        window.nameFoldersNavigation = [];
        window.fullPathFoldersNavigation = [];
        window.infoFolderParent = [];


        $("#backHeader").hide();

        window.lastFolderSel = "/home/lcservice/s3/users/admin/aziende/";

        window.fullPathFoldersNavigation.push(window.lastFolderSel);


        if (window.p_worker == null) {
            window.p_worker = "";
        }


        window.perpage = 100;
        bus.$off("bottomIconClickEvent");

        window.curSrcText = "";

        setTimeout(() => {

            this.getGroups();

        }, 100);


        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {




            $('body').on('click', '#backHeader', function () {


                pointerVue.backFolder();

            });






            $('body').on('focus', '#fldValueOtpDelete', function () {


                console.log("FLDSEARCH INPUT FOCUS");

                setTimeout(() => {


                    $(".fieldValueOtpDelete .v-input__slot").addClass("clBorderBottomGreen");

                    $(".fieldValueOtpDelete .v-text-field__slot").addClass("clBorderBottomGreen");

                }, 200);


            });





            $('body').on('focus', '#fldNameNewElement', function () {


                console.log("FLDSEARCH INPUT FOCUS");

                setTimeout(() => {


                    $(".fieldNameNewElement .v-input__slot").addClass("clBorderBottomGreen");

                    $(".fieldNameNewElement .v-text-field__slot").addClass("clBorderBottomGreen");

                }, 200);


            });






            $('body').on('focus', '#fldNameNewFolder', function () {


                console.log("FLDSEARCH INPUT FOCUS");

                setTimeout(() => {


                    $(".fieldNameNewFolder .v-input__slot").addClass("clBorderBottomGreen");

                    $(".fieldNameNewFolder .v-text-field__slot").addClass("clBorderBottomGreen");

                }, 200);


            });






            $('body').on('focus', '#fldSearchGruppi', function () {


                console.log("FLDSEARCH INPUT FOCUS");

                setTimeout(() => {


                    $(".clSearchGruppi .v-input__slot").addClass("clBorderBottomGreen");

                    $(".clSearchGruppi .v-text-field__slot").addClass("clBorderBottomGreen");

                    $(".clSearchGruppi .v-icon").addClass("clColorGreen");

                    $(".clSearchGruppi .v-icon").removeClass("primary--text");

                }, 200);


            });



            $('body').on('focus', '#fldMail', function () {


                console.log("FLDSEARCH INPUT FOCUS");



                setTimeout(() => {

                    $('label[for="fldMail"]').eq(0).removeClass("v-label");
                    $('label[for="fldMail"]').eq(0).removeClass("primary--text");
                    $('label[for="fldMail"]').eq(0).addClass("v-label-green");

                    $(".clMail .v-input__slot").addClass("clBorderBottomGreen");

                    $(".clMail .v-text-field__slot").addClass("clBorderBottomGreen");

                }, 200);


            });






            $('body').on('focus', '#fldSearch', function () {


                console.log("FLDSEARCH INPUT FOCUS");



                setTimeout(() => {


                    $(".v-input__slot").addClass("clBorderBottomGreen");

                    $(".v-text-field__slot").addClass("clBorderBottomGreen");

                }, 200);


            });




            $('body').on('input', '#fldMail', function () {


                console.log("FLDMAIL INPUT EVENT");

                pointerVue.viewPermissions = false;
                pointerVue.viewSpace = true;


            });



            $('body').on('click', '#p_enter', function () {


                console.log("P_ENTER: ", $('#p_enter').prop('checked'));

                pointerVue.updatePermissions("p_enter", $('#p_enter').prop('checked'));

            });


            $('body').on('click', '#p_show', function () {


                console.log("P_SHOW: ", $('#p_show').prop('checked'));

                pointerVue.updatePermissions("p_show", $('#p_show').prop('checked'));

            });


            $('body').on('click', '#p_remove_file', function () {


                console.log("P_REMOVE_FILE: ", $('#p_remove_file').prop('checked'));

                pointerVue.updatePermissions("p_remove_file", $('#p_remove_file').prop('checked'));

            });


            $('body').on('click', '#p_rename_file_folder', function () {


                console.log("P_RENAME_FILE_FOLDER: ", $('#p_rename_file_folder').prop('checked'));

                pointerVue.updatePermissions("p_rename_file_folder", $('#p_rename_file_folder').prop('checked'));

            });


            $('body').on('click', '#p_remove_folder', function () {


                console.log("P_REMOVE_FOLDER: ", $('#p_remove_folder').prop('checked'));

                pointerVue.updatePermissions("p_remove_folder", $('#p_remove_folder').prop('checked'));

            });

            $('body').on('click', '#p_set_password', function () {


                console.log("P_SET_PASSWORD: ", $('#p_set_password').prop('checked'));

                pointerVue.updatePermissions("p_set_password", $('#p_set_password').prop('checked'));

            });


            $('body').on('click', '#p_foldersignature', function () {


                console.log("P_FOLDERSIGNATURE: ", $('#p_foldersignature').prop('checked'));

                pointerVue.updatePermissions("p_foldersignature", $('#p_foldersignature').prop('checked'));

            });


            $('body').on('click', '#p_upload', function () {


                console.log("P_UPLOAD: ", $('#p_upload').prop('checked'));

                pointerVue.updatePermissions("p_upload", $('#p_upload').prop('checked'));

            });


            $('body').on('click', '#p_worker', function () {


                console.log("P_WORKER: ", $('#p_worker').prop('checked'));

                pointerVue.updatePermissions("p_worker", $('#p_worker').prop('checked'));

            });


            $('body').on('click', '#p_email', function () {


                console.log("P_EMAIL: ", $('#p_email').prop('checked'));

                pointerVue.updatePermissions("p_email", $('#p_email').prop('checked'));

            });

            $('body').on('click', '#p_copy', function () {


                console.log("P_COPY: ", $('#p_copy').prop('checked'));

                pointerVue.updatePermissions("p_copy", $('#p_copy').prop('checked'));

            });

            $('body').on('click', '#p_otp_delete_file', function () {


                console.log("P_OTP_DELETE_FILE: ", $('#p_otp_delete_file').prop('checked'));

                pointerVue.updatePermissions("p_otp_delete_file", $('#p_otp_delete_file').prop('checked'));

            });


            $('body').on('click', '#p_otp_delete_folder', function () {


                console.log("P_OTP_DELETE_FOLDER: ", $('#p_otp_delete_folder').prop('checked'));

                pointerVue.updatePermissions("p_otp_delete_folder", $('#p_otp_delete_folder').prop('checked'));

            });

            $('body').on('click', '#p_create_folder', function () {


                console.log("P_CREATE_FOLDER: ", $('#p_create_folder').prop('checked'));

                pointerVue.updatePermissions("p_create_folder", $('#p_create_folder').prop('checked'));

            });


            $('body').on('click', '#p_edit_permissions', function () {


                console.log("P_EDIT_PERMISSIONS: ", $('#p_edit_permissions').prop('checked'));

                pointerVue.updatePermissions("p_edit_permissions", $('#p_edit_permissions').prop('checked'));

            });






            $('body').on('click', '#cont_gruppo', function (event) {
                console.log(event);

                pointerVue.searchGruppi = "";

                pointerVue.sheetGruppi = true;


            });



            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row-doxweb");

                    $("tr").eq(0).removeClass("active-row-doxweb");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row-doxweb");


                }

                pointerVue.hideShowButtonsSelRow();


            });



        });



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);

            var a = window.app = window.$cookies.get("a");

            switch (data) {

                case "btn_remove":

                    if (a == "S") {

                        pointerVue.getOtpDelete();

                    }


                    if (a == "N") {

                        var is_file = 0;
                        var p_otp_delete_folder = 0;

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                is_file = $(this).attr("is-file");
                                p_otp_delete_folder = $(this).attr("p_otp_delete_folder");


                            }

                        });


                        if (is_file == 0) // folder
                        {


                            if (p_otp_delete_folder == 1) {

                                pointerVue.getOtpDelete();

                            }

                            if (p_otp_delete_folder == 0) {

                                pointerVue.deleteRow();

                            }

                        }


                        if (is_file == 1) { // file

                            var info_parent = window.infoFolderParent[window.infoFolderParent.length - 1];

                            console.log("INFOR PARENT FOLDER: ", info_parent[0]["p_otp_delete_file"]);


                            if (info_parent[0]["p_otp_delete_file"] == 1) {

                                pointerVue.getOtpDelete();

                            }

                            if (info_parent[0]["p_otp_delete_file"] == 0) {

                                pointerVue.deleteRow();

                            }






                        }




                    }




                    break;

                case "btn_exit":
                    break;

                case "btn_create":

                    pointerVue.nameNewFolder = "";

                    $(".contpopupaddfolder").show();

                    pointerVue.$modal.show('popupAddFolder');

                    setTimeout(() => {

                        $("#fldNameNewFolder").focus();

                    }, 200);

                    break;

                case "btn_rename":

                    var name_element2 = "";
                    var is_file2 = 0;

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {


                            name_element2 = $(this).attr("name-element");

                            is_file2 = $(this).attr("is-file");


                        }

                    });


                    console.log("NAME ELEMENT: ", name_element2);

                    this.nameNewElement = name_element2;
                    this.nameCurrentElement = name_element2;
                    this.isFile = is_file2;


                    $(".contpopuprenamefolderfile").show();

                    this.$modal.show('popupRenameFolderFile');

                    break;


                case "btn_permissions":

                    this.loadPermissions();


                    break;

                case "btn_back_root":


                    window.p_worker = window.$cookies.get("doxweb_p_worker");
                    window.askFiles = window.$cookies.get("doxweb_askFiles");
                    window.folder_level = window.$cookies.get("doxweb_folder_level");
                    window.id = window.$cookies.get("doxweb_id");
                    window.login = window.$cookies.get("doxweb_login");
                    window.useremail = window.$cookies.get("doxweb_useremail");
                    window.a = window.$cookies.get("doxweb_a");
                    window.b = window.$cookies.get("doxweb_b");
                    window.app = window.$cookies.get("doxweb_app");
                    window.curpage = undefined;

                    window.idFoldersNavigation = [];
                    window.nameFoldersNavigation = [];
                    window.fullPathFoldersNavigation = [];
                    window.infoFolderParent = [];

                    console.log("ARRAY 1: ", window.idFoldersNavigation);
                    console.log("ARRAY 2: ", window.nameFoldersNavigation);
                    console.log("ARRAY 3: ", window.fullPathFoldersNavigation);
                    console.log("ARRAY 4: ", window.infoFolderParent);

                    window.lastFolderSel = "/home/lcservice/s3/users/admin/aziende/";
                    window.fullPathFoldersNavigation.push(window.lastFolderSel);

                    $(".label_url").html("");

                    pointerVue.$root.$children[0].showProgress = true;


                    setTimeout(() => {

                        window.table.ajax.reload();

                        pointerVue.hideShowButtons(false);


                    }, 200);


                    break;

                case "btn_back":

                    window.curSrcText = "";

                    console.log("ID NAV: ", window.idFoldersNavigation);

                    console.log("PATH NAV: ", window.fullPathFoldersNavigation);

                    console.log("FOLDER LEVEL FIRST: ", window.folder_level);

                    window.folder_level = parseInt(window.folder_level) - 1;

                    console.log("FOLDER LEVEL AFTER: ", window.folder_level);

                    window.id = window.idFoldersNavigation[window.folder_level];

                    window.idFoldersNavigation.splice(window.folder_level, 1);

                    window.infoFolderParent.splice(window.folder_level, 1);

                    if (window.folder_level > 0) {

                        window.lastFolderSel = window.fullPathFoldersNavigation[window.folder_level];

                        console.log("LAST FOLDER: ", window.lastFolderSel);

                        window.fullPathFoldersNavigation.splice(window.folder_level, 1);

                    }


                    if (window.folder_level == 0) {

                        window.lastFolderSel = "/home/lcservice/s3/users/admin/aziende/";

                    }


                    // aggiorno label path navigazione
                    window.nameFoldersNavigation.splice(window.folder_level, 1);

                    var path_navigazione2 = "";


                    if (this.getWindowMobile() == 0) {

                        for (var x2 = 0; x2 < window.nameFoldersNavigation.length; x2++) {

                            if (path_navigazione2 != "") {
                                path_navigazione2 = path_navigazione2 + " / ";
                            }

                            path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x2];

                        }

                    }

                    if (this.getWindowMobile() == 1) { // mobile

                        if (window.nameFoldersNavigation.length <= 4) {

                            for (var x5 = 0; x5 < window.nameFoldersNavigation.length; x5++) {

                                if (path_navigazione2 != "") {
                                    path_navigazione2 = path_navigazione2 + " / ";
                                }

                                path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x5];

                            }


                        }



                        if (window.nameFoldersNavigation.length > 3) {

                            path_navigazione2 = "..";

                            var x_start2 = 0;
                            var len_array2 = window.nameFoldersNavigation.length;

                            if (len_array2 >= 4 && len_array2 <= 6) {
                                x_start2 = 3;
                            }

                            if (len_array2 > 6 && len_array2 <= 8) {
                                x_start2 = 4;
                            }

                            if (len_array2 > 8 && len_array2 <= 10) {
                                x_start2 = 5;
                            }



                            for (var x6 = x_start2; x6 < window.nameFoldersNavigation.length; x6++) {

                                if (path_navigazione2 != "") {
                                    path_navigazione2 = path_navigazione2 + " / ";
                                }

                                path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x6];

                            }

                        }





                    }




                    $(".label_url").html(path_navigazione2);




                    console.log("FOLDER ID: ", window.id);

                    if (window.id != "#") {
                        window.curpage = 0;
                        window.askFiles = 1;
                    }
                    else {
                        window.curpage = undefined;
                        window.askFiles = 1;

                        $("#backHeader").hide();

                    }

                    window.app = "doxweb";

                    console.log("FOLDER CURPAGE: ", window.curpage);
                    console.log("FOLDER ASKFILES: ", window.askFiles);

                    pointerVue.$root.$children[0].showProgress = true;


                    setTimeout(() => {

                        window.table.ajax.reload();

                        pointerVue.hideShowButtons(false);


                    }, 200);

                    break;

                case "btn_view":


                    pointerVue.viewFolder();


                    break;


                case "btn_download":




                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    setTimeout(() => {

                        var file_path = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {


                                file_path = $(this).attr("file-path");

                            }

                        });



                        var url_call = pointerVue.getUrlDomain("downloadFileNew") + "?path_file=" + file_path;

                        console.log("URL TO CALL: ", url_call);

                        window.app = "doxweb";

                        window.table.ajax.reload();

                        window.open(url_call, "_blank");


                        setTimeout(() => {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;



                        }, 1000);


                    }, 500);





                    break;


                case "btn_upload":

                    this.openPopupUpload();

                    break;



                default:
                    break;

            }
        });


        this.setupButtonsGrid();


        window.start = 0;

        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({

        userMail: "",

        viewSpace: false,
        viewSpaceGroup: false,

        viewButtonConfirm: false,

        viewPermissions: false,

        mailSel: "",

        viewGroups: false,
        viewMail: false,

        searchGruppi: "",

        sheetGruppi: false,

        selGruppo: "",

        valueSelected: "",

        eleSelectedPermissions: "",

        // parametri popup upload
        titoloPopup: "Upload File",
        fileAccettati: ".pdf,.doc,.docx,.xls,.xlsx,.csv,.zip,.gif,.tiff,.png,.jpg,.rtf,.mp3,.mp4",
        nomeFilePresente: "",
        dimFilePresente: "",
        dimKbFilePresente: 0,




        isFile: 0,

        nameNewElement: "",

        nameCurrentElement: "",

        nameNewFolder: "",

        valueOtpDelete: "",

        sheetAziende: false,
        search: "",

        textSearch: "",

        aziende: [],


        gruppi: [],


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredGruppi() {

            console.log("GRUPPI: ", this.gruppi);

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.full_name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

    },


    methods: {

        backFolder: function () {

            var that = this;

            window.curSrcText = "";

            console.log("ID NAV: ", window.idFoldersNavigation);

            console.log("PATH NAV: ", window.fullPathFoldersNavigation);

            console.log("FOLDER LEVEL FIRST: ", window.folder_level);

            window.folder_level = parseInt(window.folder_level) - 1;

            console.log("FOLDER LEVEL AFTER: ", window.folder_level);

            window.id = window.idFoldersNavigation[window.folder_level];

            window.idFoldersNavigation.splice(window.folder_level, 1);

            window.infoFolderParent.splice(window.folder_level, 1);

            if (window.folder_level > 0) {

                window.lastFolderSel = window.fullPathFoldersNavigation[window.folder_level];

                console.log("LAST FOLDER: ", window.lastFolderSel);

                window.fullPathFoldersNavigation.splice(window.folder_level, 1);

            }


            if (window.folder_level == 0) {

                window.lastFolderSel = "/home/lcservice/s3/users/admin/aziende/";

            }


            // aggiorno label path navigazione
            window.nameFoldersNavigation.splice(window.folder_level, 1);

            var path_navigazione2 = "";


            if (this.getWindowMobile() == 0) {

                for (var x2 = 0; x2 < window.nameFoldersNavigation.length; x2++) {

                    if (path_navigazione2 != "") {
                        path_navigazione2 = path_navigazione2 + " / ";
                    }

                    path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x2];

                }

            }

            if (this.getWindowMobile() == 1) { // mobile

                if (window.nameFoldersNavigation.length <= 4) {

                    for (var x5 = 0; x5 < window.nameFoldersNavigation.length; x5++) {

                        if (path_navigazione2 != "") {
                            path_navigazione2 = path_navigazione2 + " / ";
                        }

                        path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x5];

                    }


                }



                if (window.nameFoldersNavigation.length > 3) {

                    path_navigazione2 = "..";

                    var x_start2 = 0;
                    var len_array2 = window.nameFoldersNavigation.length;

                    if (len_array2 >= 4 && len_array2 <= 6) {
                        x_start2 = 3;
                    }

                    if (len_array2 > 6 && len_array2 <= 8) {
                        x_start2 = 4;
                    }

                    if (len_array2 > 8 && len_array2 <= 10) {
                        x_start2 = 5;
                    }



                    for (var x6 = x_start2; x6 < window.nameFoldersNavigation.length; x6++) {

                        if (path_navigazione2 != "") {
                            path_navigazione2 = path_navigazione2 + " / ";
                        }

                        path_navigazione2 = path_navigazione2 + window.nameFoldersNavigation[x6];

                    }

                }





            }




            $(".label_url").html(path_navigazione2);




            console.log("FOLDER ID: ", window.id);

            if (window.id != "#") {
                window.curpage = 0;
                window.askFiles = 1;
            }
            else {
                window.curpage = undefined;
                window.askFiles = 1;

                $("#backHeader").hide();

            }

            window.app = "doxweb";

            console.log("FOLDER CURPAGE: ", window.curpage);
            console.log("FOLDER ASKFILES: ", window.askFiles);

            that.$root.$children[0].showProgress = true;


            setTimeout(() => {

                window.table.ajax.reload();

                that.hideShowButtons(false);


            }, 200);



        },

        backToRoot: function () {

            var that = this;

            window.p_worker = window.$cookies.get("doxweb_p_worker");
            window.askFiles = window.$cookies.get("doxweb_askFiles");
            window.folder_level = window.$cookies.get("doxweb_folder_level");
            window.id = window.$cookies.get("doxweb_id");
            window.login = window.$cookies.get("doxweb_login");
            window.useremail = window.$cookies.get("doxweb_useremail");
            window.a = window.$cookies.get("doxweb_a");
            window.b = window.$cookies.get("doxweb_b");
            window.app = window.$cookies.get("doxweb_app");
            window.curpage = undefined;

            window.idFoldersNavigation = [];
            window.nameFoldersNavigation = [];
            window.fullPathFoldersNavigation = [];
            window.infoFolderParent = [];

            console.log("ARRAY 1: ", window.idFoldersNavigation);
            console.log("ARRAY 2: ", window.nameFoldersNavigation);
            console.log("ARRAY 3: ", window.fullPathFoldersNavigation);
            console.log("ARRAY 4: ", window.infoFolderParent);

            window.lastFolderSel = "/home/lcservice/s3/users/admin/aziende/";
            window.fullPathFoldersNavigation.push(window.lastFolderSel);

            $(".label_url").html("");

            $("#backHeader").hide();

            that.$root.$children[0].showProgress = true;


            setTimeout(() => {

                window.table.ajax.reload();

                that.hideShowButtons(false);


            }, 200);


        },

        btnConfirmGroupOrMail: function () {

            var errore = "";


            if (this.valueSelected == "E") {

                if (this.mailSel == "") {

                    errore = "E-Mail required";

                }


            }

            if (this.valueSelected == "G") {

                if (this.selGruppo == "") {

                    errore = "Group required";

                }


            }

            if (errore == "") {

                if (this.valueSelected == "E") {


                    this.loadPermissionsMail();


                }

                if (this.valueSelected == "G") {


                    this.loadPermissionsGroup();


                }

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }




        },


        chgRadio: function () {

            console.log("CHANGE: ", this.valueSelected);

            if (this.valueSelected == "G") {

                this.mailSel = "";
                this.viewMail = false;
                this.selGruppo = "";
                this.viewGroups = true;
                this.viewPermissions = false;
                this.viewButtonConfirm = true;
                this.viewSpace = false;
                this.viewSpaceGroup = true;

            }

            if (this.valueSelected == "E") {

                this.selGruppo = "";
                this.viewGroups = false;
                this.mailSel = "";
                this.viewMail = true;
                this.viewPermissions = false;
                this.viewButtonConfirm = true;
                this.viewSpace = true;
                this.viewSpaceGroup = false;

            }

        },


        manageClickGruppi: async function (gruppo) {

            console.log("AZI SEL: ", gruppo);

            this.sheetGruppi = false;

            this.selGruppo = gruppo.idresources_group_name;

            this.viewPermissions = false;
            this.viewSpaceGroup = true;

        },


        btnCancelPermissions: function () {

            this.$modal.hide('popupPermissions');

        },


        getGroups: async function () {

            var that = this;

            var response = await apidoxweb.getGroups(
                atob(window.$cookies.get("token"))
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getGroups", res);

                that.gruppi = res.data.data.myGroups;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        updatePermissions: async function (v_name, v_value) {

            var that = this;

            var dest = "";
            var group_id = "";
            var lastSelection = "";

            if (that.valueSelected == "G") // gruppo
            {
                group_id = that.selGruppo;
                lastSelection = that.selGruppo;
            }

            if (that.valueSelected == "E") // email
            {

                dest = that.mailSel;
                lastSelection = that.mailSel;
            }


            var login = atob(window.$cookies.get("token"));

            var v_id = "";
            var v_text = "";
            var v_icon = true;
            var v_folder_file = "";
            var val = 0;
            var field = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (v_id != "") {
                        v_id = v_id + ",";
                    }
                    v_id = v_id + $(this).attr("data-id");

                    v_text = $(this).attr("name-element");

                    v_folder_file = $(this).attr("folder_file");

                }

            });

            var nodeOriginalArray = [{ folder_file: v_folder_file }];

            var nodeArray = [{ id: v_id, text: v_text, icon: v_icon, original: nodeOriginalArray }];

            var node = JSON.stringify(nodeArray);


            field = v_name;

            if (v_value) {
                val = 1;
            }


            console.log("DEST: ", dest);
            console.log("GROUP_ID: ", group_id);
            console.log("LOGIN: ", login);
            console.log("LASTSELECTION: ", lastSelection);
            console.log("NODE: ", node);
            console.log("FIELD: ", field);
            console.log("VAL: ", val);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.updatePermissions(
                dest,
                group_id,
                login,
                node,
                lastSelection,
                field,
                val
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updatePermissions", res);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },









        loadPermissionsGroup: async function () {

            var that = this;

            var dest = "";
            var group_id = that.selGruppo;
            var login = atob(window.$cookies.get("token"));
            var lastSelection = that.selGruppo;

            var v_id = "";
            var v_text = "";
            var v_icon = true;
            var v_folder_file = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (v_id != "") {
                        v_id = v_id + ",";
                    }
                    v_id = v_id + $(this).attr("data-id");

                    v_text = $(this).attr("name-element");

                    v_folder_file = $(this).attr("folder_file");

                }

            });

            if (v_folder_file == "") {
                v_folder_file = window.lastFolderSel;

                v_id = window.infoFolderParent[window.infoFolderParent.length - 1][0]["data_id"];
                v_text = window.infoFolderParent[window.infoFolderParent.length - 1][0]["name_element"];

                console.log("v_FOLDER_FILE: ", v_folder_file);
                console.log("V_ID: ", v_id);
                console.log("V_TEXT: ", v_text);

            }





            var nodeOriginalArray = [{ folder_file: v_folder_file }];


            var nodeArray = [{ id: v_id, text: v_text, icon: v_icon, original: nodeOriginalArray }];


            var node = JSON.stringify(nodeArray);

            console.log("DEST: ", dest);
            console.log("GROUP_ID: ", group_id);
            console.log("LOGIN: ", login);
            console.log("LASTSELECTION: ", lastSelection);
            console.log("NODE: ", node);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.loadPermissions(
                dest,
                group_id,
                login,
                node,
                lastSelection
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from loadPermissions", res);


                that.viewSpaceGroup = false;
                that.viewPermissions = true;


                setTimeout(() => {

                    $("#p_enter").prop("checked", false);
                    $("#p_show").prop("checked", false);
                    $("#p_remove_file").prop("checked", false);
                    $("#p_rename_file_folder").prop("checked", false);
                    $("#p_remove_folder").prop("checked", false);
                    $("#p_set_password").prop("checked", false);
                    $("#p_foldersignature").prop("checked", false);
                    $("#p_upload").prop("checked", false);
                    $("#p_worker").prop("checked", false);
                    $("#p_email").prop("checked", false);
                    $("#p_copy").prop("checked", false);
                    $("#p_otp_delete_file").prop("checked", false);
                    $("#p_otp_delete_folder").prop("checked", false);
                    $("#p_create_folder").prop("checked", false);
                    $("#p_edit_permissions").prop("checked", false);

                    if (res.data.singleShare != null) {

                        if (res.data.singleShare.p_enter == 1) {

                            $("#p_enter").prop("checked", true);

                        }

                        if (res.data.singleShare.p_show == 1) {

                            $("#p_show").prop("checked", true);

                        }

                        if (res.data.singleShare.p_remove_file == 1) {

                            $("#p_remove_file").prop("checked", true);

                        }

                        if (res.data.singleShare.p_rename_file_folder == 1) {

                            $("#p_rename_file_folder").prop("checked", true);

                        }


                        if (res.data.singleShare.p_remove_folder == 1) {

                            $("#p_remove_folder").prop("checked", true);

                        }


                        if (res.data.singleShare.p_set_password == 1) {

                            $("#p_set_password").prop("checked", true);

                        }

                        if (res.data.singleShare.p_foldersignature == 1) {

                            $("#p_foldersignature").prop("checked", true);

                        }


                        if (res.data.singleShare.p_upload == 1) {

                            $("#p_upload").prop("checked", true);

                        }

                        if (res.data.singleShare.p_worker == 1) {

                            $("#p_worker").prop("checked", true);

                        }

                        if (res.data.singleShare.p_email == 1) {

                            $("#p_email").prop("checked", true);

                        }

                        if (res.data.singleShare.p_copy == 1) {

                            $("#p_copy").prop("checked", true);

                        }

                        if (res.data.singleShare.p_otp_delete_file == 1) {

                            $("#p_otp_delete_file").prop("checked", true);

                        }

                        if (res.data.singleShare.p_otp_delete_folder == 1) {

                            $("#p_otp_delete_folder").prop("checked", true);

                        }

                        if (res.data.singleShare.p_create_folder == 1) {

                            $("#p_create_folder").prop("checked", true);

                        }


                        if (res.data.singleShare.p_edit_permissions == 1) {

                            $("#p_edit_permissions").prop("checked", true);

                        }




                    }


                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },



        loadPermissionsMail: async function () {

            var that = this;

            var dest = that.mailSel;
            var group_id = "";
            var login = atob(window.$cookies.get("token"));
            var lastSelection = that.mailSel;

            var v_id = "";
            var v_text = "";
            var v_icon = true;
            var v_folder_file = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (v_id != "") {
                        v_id = v_id + ",";
                    }
                    v_id = v_id + $(this).attr("data-id");

                    v_text = $(this).attr("name-element");

                    v_folder_file = $(this).attr("folder_file");

                }

            });


            if (v_folder_file == "") {
                v_folder_file = window.lastFolderSel;

                v_id = window.infoFolderParent[window.infoFolderParent.length - 1][0]["data_id"];
                v_text = window.infoFolderParent[window.infoFolderParent.length - 1][0]["name_element"];

                console.log("v_FOLDER_FILE: ", v_folder_file);
                console.log("V_ID: ", v_id);
                console.log("V_TEXT: ", v_text);

            }






            var nodeOriginalArray = [{ folder_file: v_folder_file }];


            var nodeArray = [{ id: v_id, text: v_text, icon: v_icon, original: nodeOriginalArray }];


            var node = JSON.stringify(nodeArray);

            console.log("DEST: ", dest);
            console.log("GROUP_ID: ", group_id);
            console.log("LOGIN: ", login);
            console.log("LASTSELECTION: ", lastSelection);
            console.log("NODE: ", node);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.loadPermissions(
                dest,
                group_id,
                login,
                node,
                lastSelection
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from loadPermissions", res);

                that.viewSpace = false;
                that.viewPermissions = true;


                setTimeout(() => {

                    $("#p_enter").prop("checked", false);
                    $("#p_show").prop("checked", false);
                    $("#p_remove_file").prop("checked", false);
                    $("#p_rename_file_folder").prop("checked", false);
                    $("#p_remove_folder").prop("checked", false);
                    $("#p_set_password").prop("checked", false);
                    $("#p_foldersignature").prop("checked", false);
                    $("#p_upload").prop("checked", false);
                    $("#p_worker").prop("checked", false);
                    $("#p_email").prop("checked", false);
                    $("#p_copy").prop("checked", false);
                    $("#p_otp_delete_file").prop("checked", false);
                    $("#p_otp_delete_folder").prop("checked", false);
                    $("#p_create_folder").prop("checked", false);
                    $("#p_edit_permissions").prop("checked", false);

                    if (res.data.singleShare != null) {

                        if (res.data.singleShare.p_enter == 1) {

                            $("#p_enter").prop("checked", true);

                        }

                        if (res.data.singleShare.p_show == 1) {

                            $("#p_show").prop("checked", true);

                        }

                        if (res.data.singleShare.p_remove_file == 1) {

                            $("#p_remove_file").prop("checked", true);

                        }

                        if (res.data.singleShare.p_rename_file_folder == 1) {

                            $("#p_rename_file_folder").prop("checked", true);

                        }


                        if (res.data.singleShare.p_remove_folder == 1) {

                            $("#p_remove_folder").prop("checked", true);

                        }


                        if (res.data.singleShare.p_set_password == 1) {

                            $("#p_set_password").prop("checked", true);

                        }

                        if (res.data.singleShare.p_foldersignature == 1) {

                            $("#p_foldersignature").prop("checked", true);

                        }


                        if (res.data.singleShare.p_upload == 1) {

                            $("#p_upload").prop("checked", true);

                        }

                        if (res.data.singleShare.p_worker == 1) {

                            $("#p_worker").prop("checked", true);

                        }

                        if (res.data.singleShare.p_email == 1) {

                            $("#p_email").prop("checked", true);

                        }

                        if (res.data.singleShare.p_copy == 1) {

                            $("#p_copy").prop("checked", true);

                        }

                        if (res.data.singleShare.p_otp_delete_file == 1) {

                            $("#p_otp_delete_file").prop("checked", true);

                        }

                        if (res.data.singleShare.p_otp_delete_folder == 1) {

                            $("#p_otp_delete_folder").prop("checked", true);

                        }

                        if (res.data.singleShare.p_create_folder == 1) {

                            $("#p_create_folder").prop("checked", true);

                        }

                        if (res.data.singleShare.p_edit_permissions == 1) {

                            $("#p_edit_permissions").prop("checked", true);

                        }

                    }


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },






        loadPermissions: async function () {

            var that = this;

            that.valueSelected = "";
            that.selGruppo = "";
            that.mailSel = "";
            that.viewGroups = false;
            that.viewMail = false;
            that.viewPermissions = false;
            that.viewButtonConfirm = false;
            that.viewSpace = false;

            var dest = "";
            var group_id = "";
            var login = atob(window.$cookies.get("token"));
            var lastSelection = "";

            var v_id = "";
            var v_text = "";
            var v_icon = true;
            var v_folder_file = "";

            var is_folder_sel = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    if (v_id != "") {
                        v_id = v_id + ",";
                    }
                    v_id = v_id + $(this).attr("data-id");

                    v_text = $(this).attr("name-element");

                    v_folder_file = $(this).attr("folder_file");

                    is_folder_sel = 1;

                }

            });


            if (v_folder_file == "") {
                v_folder_file = window.lastFolderSel;

                v_id = window.infoFolderParent[window.infoFolderParent.length - 1][0]["data_id"];
                v_text = window.infoFolderParent[window.infoFolderParent.length - 1][0]["name_element"];

                console.log("v_FOLDER_FILE: ", v_folder_file);
                console.log("V_ID: ", v_id);
                console.log("V_TEXT: ", v_text);

            }





            var nodeOriginalArray = [{ folder_file: v_folder_file }];


            var nodeArray = [{ id: v_id, text: v_text, icon: v_icon, original: nodeOriginalArray }];


            var node = JSON.stringify(nodeArray);

            console.log("DEST: ", dest);
            console.log("GROUP_ID: ", group_id);
            console.log("LOGIN: ", login);
            console.log("LASTSELECTION: ", lastSelection);
            console.log("NODE: ", node);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.loadPermissions(
                dest,
                group_id,
                login,
                node,
                lastSelection
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from loadPermissions", res);

                var v_path_navigation = that.getPathNavigazionePermissions();


                if (is_folder_sel == 1) {

                    if (v_path_navigation != "") {
                        v_path_navigation = v_path_navigation + " / ";
                    }

                    v_path_navigation = v_path_navigation + v_text;

                }


                console.log("V_PATH_NAVIGATION: ", v_path_navigation);

                that.eleSelectedPermissions = v_path_navigation;


                setTimeout(() => {

                    $(".contpopuppermissions").show();

                    that.$modal.show('popupPermissions');


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },





        deleteRow: async function () {

            var that = this;

            var ids = "";
            var is_file = "";
            var name_element = "";
            var tot = 0;
            var tot_file = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");

                    is_file = $(this).attr("is-file");

                    if (is_file == 1) {

                        tot_file = tot_file + 1;
                    }

                    name_element = $(this).attr("name-element");

                }

            });



            var txt_msg = "";

            if (tot == 1) {

                if (is_file == 0) {
                    txt_msg = "Are you sure you want to delete the " + name_element + " folder ?";
                }

                if (is_file == 1) {
                    txt_msg = "Are you sure you want to delete the " + name_element + " file ?";
                }

            }

            if (tot > 1) {

                txt_msg = "Are you sure you want to delete selected files ?";


            }


            var htmlCancelButton = "<img title='Cancel' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_cancel3_doxweb.png") + "' />";

            var htmlConfirmButton = "<img title='Confirm' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_confirm3_doxweb.png") + "' />";


            that.$swal
                .fire({
                    title: "Remove",
                    html: txt_msg,
                    icon: "warning",
                    iconColor: "#6cc115",
                    showCancelButton: true,
                    confirmButtonText: htmlConfirmButton,
                    cancelButtonText: htmlCancelButton,
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {


                        if (tot == 1) {

                            if (is_file == 0) // elimino folder
                            {

                                var id = "";
                                var folder_file = "";
                                var action = "";
                                var login = "";
                                var a = "";
                                var b = "";
                                var app = "";

                                $(".val_status").each(function (index) {

                                    console.log(index);

                                    if ($(this).prop("checked")) {

                                        if (id != "") {
                                            id = id + ",";
                                        }
                                        id = id + $(this).attr("data-id");

                                        folder_file = $(this).attr("folder_file");

                                    }

                                });

                                id = btoa(id);
                                folder_file = btoa(folder_file);

                                action = "remove";
                                app = "doxweb";

                                var v_a = window.$cookies.get("a");

                                if (v_a == "S") {
                                    login = "admin";
                                }
                                else {

                                    login = atob(window.$cookies.get("token"));

                                }

                                a = window.$cookies.get("token");
                                b = window.$cookies.get("token_system");

                                console.log("APP:", app);
                                console.log("FOLDER_FILE:", folder_file);
                                console.log("ACTION:", action);
                                console.log("ID:", id);
                                console.log("LOGIN:", login);
                                console.log("a:", a);
                                console.log("b:", b);





                                that.showSpinner = true;
                                that.$root.$children[0].showProgress = true;

                                var response = await apidoxweb.deleteFolder(
                                    app,
                                    folder_file,
                                    action,
                                    id,
                                    login,
                                    a,
                                    b
                                ).then((res) => {

                                    that.$root.$children[0].showProgress = false;
                                    console.log("res from deleteFolder", res);

                                    that.$swal({
                                        icon: "success",
                                        text: "Deletion successful",
                                        showConfirmButton: false,
                                        timer: 2000
                                    });

                                    setTimeout(() => {

                                        that.dialogMsg = false;
                                        that.setupButtons();



                                        window.table.ajax.reload();

                                        that.hideShowButtons(false);


                                    }, 200);

                                }).catch(err => {
                                    that.$root.$children[0].showProgress = false;
                                    console.log(err);
                                    var msg = err.response.data.Error;

                                    that.$swal({
                                        icon: "error",
                                        text: msg,
                                        showConfirmButton: false,
                                        timer: 8000
                                    });
                                    console.log("Errori", "Non è stato possibile eliminare i dati");
                                    console.log("response", response);

                                }

                                );


                            }


                            if (is_file == 1) // elimino file
                            {



                                var action2 = "";
                                var key2 = "";
                                var keys2 = "";

                                $(".val_status").each(function (index) {

                                    console.log(index);

                                    if ($(this).prop("checked")) {

                                        key2 = $(this).attr("file-path");

                                        if (keys2 != "") {
                                            keys2 = keys2 + ",";
                                        }
                                        keys2 = keys2 + key2;

                                    }

                                });


                                action2 = "remove";


                                console.log("ACTION:", action2);
                                console.log("KEY:", key2);
                                console.log("KEYS:", keys2);


                                that.showSpinner = true;
                                that.$root.$children[0].showProgress = true;

                                var response2 = await apidoxweb.deleteFile(
                                    action2,
                                    key2,
                                    keys2
                                ).then((res2) => {

                                    that.$root.$children[0].showProgress = false;
                                    console.log("res from deleteFile", res2);

                                    that.$swal({
                                        icon: "success",
                                        text: "Deletion successful",
                                        showConfirmButton: false,
                                        timer: 2000
                                    });

                                    setTimeout(() => {

                                        that.dialogMsg = false;
                                        that.setupButtons();



                                        window.table.ajax.reload();

                                        that.hideShowButtons(false);


                                    }, 200);

                                }).catch(err2 => {
                                    that.$root.$children[0].showProgress = false;
                                    console.log(err2);
                                    var msg2 = err2.response.data.Error;

                                    that.$swal({
                                        icon: "error",
                                        text: msg2,
                                        showConfirmButton: false,
                                        timer: 8000
                                    });
                                    console.log("Errori", "Non è stato possibile eliminare i dati");
                                    console.log("response", response2);

                                }

                                );





                            }


                        }

                        if (tot > 1) {

                            var action3 = "";
                            var key3 = "";
                            var keys3 = "";

                            $(".val_status").each(function (index) {

                                console.log(index);

                                if ($(this).prop("checked")) {

                                    key3 = $(this).attr("file-path");

                                    if (keys3 != "") {
                                        keys3 = keys3 + ",";
                                    }
                                    keys3 = keys3 + key3;

                                }

                            });


                            action3 = "remove";


                            console.log("ACTION:", action3);
                            console.log("KEY:", key3);
                            console.log("KEYS:", keys3);


                            that.showSpinner = true;
                            that.$root.$children[0].showProgress = true;

                            var response3 = await apidoxweb.deleteFile(
                                action3,
                                key3,
                                keys3
                            ).then((res3) => {

                                that.$root.$children[0].showProgress = false;
                                console.log("res from deleteFile", res3);

                                that.$swal({
                                    icon: "success",
                                    text: "Deletion successful",
                                    showConfirmButton: false,
                                    timer: 2000
                                });

                                setTimeout(() => {

                                    that.dialogMsg = false;
                                    that.setupButtons();



                                    window.table.ajax.reload();

                                    that.hideShowButtons(false);


                                }, 200);

                            }).catch(err3 => {
                                that.$root.$children[0].showProgress = false;
                                console.log(err3);
                                var msg3 = err3.response.data.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg3,
                                    showConfirmButton: false,
                                    timer: 8000
                                });
                                console.log("Errori", "Non è stato possibile eliminare i dati");
                                console.log("response", response3);

                            }

                            );


                        }








                    }

                });

        },





        deleteRowOld: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apiusers.deleteUtente(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteUtente", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();



                                window.table.ajax.reload();

                                that.hideShowButtons(false);


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },






        btnCancelOtpDelete: function () {

            this.$modal.hide('popupOtpDelete');


        },

        btnConfirmOtpDelete: function () {


            if (this.valueOtpDelete != "") {

                console.log("OTP INP: ", this.valueOtpDelete);
                console.log("ENC OTP INP: ", btoa(this.valueOtpDelete));

                if (window.myVal == btoa(this.valueOtpDelete)) {

                    this.$modal.hide('popupOtpDelete');

                    this.deleteRow();

                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },




        getOtpDelete: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";
            var is_file = "";
            var name_element = "";
            var tot = 0;
            var tot_files = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");

                    is_file = $(this).attr("is-file");

                    if (is_file == 1) {
                        tot_files = tot_files + 1;
                    }

                    name_element = $(this).attr("name-element");

                }

            });


            var desc_op = "";

            if (tot == 1) {

                desc_op = "elimina_folder";
                if (is_file == 1) {
                    desc_op = "elimina_file";
                }

            }

            if (tot > 1) {

                desc_op = "elimina_files";


            }

            console.log("IDS SEL:", ids);


            window.myVal = "";

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiotp.generateOTPAndSendMailDox(
                v_token,
                "doxweb",
                desc_op,
                ids,
                name_element
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from generateOTPAndSendMailDox", res);

                window.myVal = res.data.Result;


                setTimeout(() => {

                    $(".contpopupotpdelete").show();

                    this.$modal.show('popupOtpDelete');

                    setTimeout(() => {

                        $("#fldValueOtpDelete").focus();

                    }, 300);


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },




        openPopupUpload: async function () {


            console.log("ARRAY FOLDER PARENT: ", window.infoFolderParent);



            var that = this;


            that.nomeFilePresente = "";
            that.dimFilePresente = "";
            that.dimKbFilePresente = 0;



            var errore = "";


            if (errore == "") {

                var v_token = window.$cookies.get("token");


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response3 = await apidoxweb.getInfoUploadFile(
                    v_token
                ).then((res2) => {

                    console.log(response3);

                    that.$root.$children[0].showProgress = false;
                    console.log("res from getInfoUploadFile", res2);


                    that.dimMinKbFile = res2.data.MinFileSize;

                    that.dimMaxKbFile = res2.data.MaxFileSize;

                    that.userMail = res2.data.UserEMail;

                    $(".contpopupuploadfile").show();

                    this.$modal.show('popupUploadFile');


                    setTimeout(() => {


                        var oggDrop = $('.dropify').dropify({
                            messages: {
                                default: 'Drag',
                                replace: /* s */ '',
                                remove: 'Rimuovi',
                                error: 'File troppo grande'
                            }
                        });

                        $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                        $(".dropify-render").append("<img>");

                        oggDrop.on('dropify.afterClear', function (event, element) {

                            console.log(event, element);

                            that.removeUploadFile = 1;

                            that.nomeFilePresente = "";
                            that.dimFilePresente = "";
                            that.dimKbFilePresente = 0;

                        });



                        $('#input-file-attach').on('change', function () {

                            that.removeUploadFile = 0;

                            var v_file = $("#input-file-attach")[0].files[0];

                            console.log("FILE: ", v_file);

                            that.nomeFilePresente = v_file.name;

                            var v_size = v_file.size;

                            that.dimKbFilePresente = v_size;

                            var str_size = "0 Kb";


                            if (v_size > 0) {

                                var v_division = v_size / 1000;

                                str_size = v_division.toFixed(1) + " Kb";


                            }



                            that.dimFilePresente = str_size;



                        });



                        console.log("FILE PRESENT: ", res2.data.NomeFile);

                        if (res2.data.NomeFile != null) {

                            if (res2.data.NomeFile != "") {

                                var elements = res2.data.NomeFile.split(".");

                                console.log("ELEMENTS: ", elements);

                                var v_ext = elements[elements.length - 1];

                                console.log("EXT: ", v_ext);

                                $(".rowPrevFile").find('.dropify-render').append("<i class='dropify-font-file'></i>");
                                $(".rowPrevFile").find('.dropify-render').append("<span class='dropify-extension'>" + v_ext + "</span>");
                                $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                $(".rowPrevFile").find(".dropify-preview").show("slow");



                                that.nomeFilePresente = res2.data.NomeFile;

                                that.dimKbFilePresente = res2.data.FileSize;


                                var str_size = "0 Kb";

                                var v_size = res2.data.FileSize;

                                if (v_size > 0) {

                                    var v_division = v_size / 1000;

                                    str_size = v_division.toFixed(1) + " Kb";


                                }



                                that.dimFilePresente = str_size;

                            }


                        }








                    }, 300);



                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }




        },

        btnCancelUpload: function () {

            this.$modal.hide('popupUploadFile');

        },

        checkFileExist: async function () {


            var that = this;

            var folder = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    folder = $(this).attr("folder_file");

                }

            });

            if (folder == "") {

                folder = window.lastFolderSel;

            }



            var v_file = $("#input-file-attach")[0].files[0].name;


            console.log("FOLDER_FILE: ", folder);
            console.log("FILE: ", v_file);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.checkFileExist(
                folder,
                v_file
            ).then((res) => {

                console.log("res from checkFileExist", res);


                if (res.data.result == "OK") // il file non esiste
                {

                    that.uploadFile();

                }
                else // esiste chiedo conferma se si vuole sovrascriverlo
                {

                    that.$root.$children[0].showProgress = false;

                    var txt_msg = "The file " + v_file + " already exists, do you want to overwrite it ?";

                    var htmlCancelButton = "<img title='Cancel' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_cancel3_doxweb.png") + "' />";

                    var htmlConfirmButton = "<img title='Confirm' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_confirm3_doxweb.png") + "' />";

                    that.$swal
                        .fire({
                            title: "Overwrite",
                            html: txt_msg,
                            icon: "warning",
                            iconColor: "#6cc115",
                            showCancelButton: true,
                            confirmButtonText: htmlConfirmButton,
                            cancelButtonText: htmlCancelButton,
                            customClass: {
                                cancelButton: 'order-1',
                                confirmButton: 'order-2',
                            }

                        })
                        .then(async function (result) {

                            if (result.value == true) {



                                console.log("ok");

                                that.uploadFile();




                            }

                        });





                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );



        },

        btnConfirmUpload: function () {

            var errore = "";

            var v_file = $("#input-file-attach")[0];

            if (v_file.files.length == 0) {

                errore = "File required";

            }

            if (errore == "") {

                if (v_file.files.length > 0) {



                    // controllo estensione

                    v_file = v_file.files[0];

                    console.log("FILE 1: ", v_file);

                    var v_elements = v_file.name.split('.');

                    var v_ext_file = v_elements[v_elements.length - 1];

                    console.log("FILE ELEMENTS: ", v_elements);
                    console.log("FILE EXT: ", v_ext_file);


                    var v_ele_file_accept = this.fileAccettati.split(',');

                    console.log("FILE ELEMENTS ACCEPT: ", v_ele_file_accept);

                    var v_find_ext = 0;

                    for (var xx = 0; xx < v_ele_file_accept.length; xx++) {

                        var v_ext_file_tmp = "." + v_ext_file;

                        console.log(v_ext_file_tmp);

                        if (v_ext_file_tmp == v_ele_file_accept[xx]) {
                            v_find_ext = 1;
                        }

                    }

                    if (v_find_ext == 0) {
                        errore = "The accepted file formats are " + this.fileAccettati;
                    }



                    if (errore == "") {

                        // controllo dimensione file

                        if ((this.dimKbFilePresente < this.dimMinKbFile) || (this.dimKbFilePresente > this.dimMaxKbFile)) {


                            var strMin = (this.dimMinKbFile / 1000) + " Kb";

                            var strMax = (this.dimMaxKbFile / 1000000) + " Mb";

                            errore = "The file size must be between " + strMin + " and " + strMax;

                        }


                    }






                }

            }






            if (errore == "") {

                console.log("ok");

                //this.uploadFile();

                this.checkFileExist();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 4000
                });


            }


        },







        btnConfirmUploadOld: function () {

            var errore = "";

            var v_file = $("#input-file-attach")[0];

            if (v_file.files.length == 0) {

                errore = "File required";

            }

            if (errore == "") {

                if (v_file.files.length > 0) {



                    // controllo estensione

                    v_file = v_file.files[0];

                    console.log("FILE 1: ", v_file);

                    var v_elements = v_file.name.split('.');

                    var v_ext_file = v_elements[v_elements.length - 1];

                    console.log("FILE ELEMENTS: ", v_elements);
                    console.log("FILE EXT: ", v_ext_file);


                    var v_ele_file_accept = this.fileAccettati.split(',');

                    console.log("FILE ELEMENTS ACCEPT: ", v_ele_file_accept);

                    var v_find_ext = 0;

                    for (var xx = 0; xx < v_ele_file_accept.length; xx++) {

                        var v_ext_file_tmp = "." + v_ext_file;

                        console.log(v_ext_file_tmp);

                        if (v_ext_file_tmp == v_ele_file_accept[xx]) {
                            v_find_ext = 1;
                        }

                    }

                    if (v_find_ext == 0) {
                        errore = "The accepted file formats are " + this.fileAccettati;
                    }



                    if (errore == "") {

                        // controllo dimensione file

                        if ((this.dimKbFilePresente < this.dimMinKbFile) || (this.dimKbFilePresente > this.dimMaxKbFile)) {


                            var strMin = (this.dimMinKbFile / 1000) + " Kb";

                            var strMax = (this.dimMaxKbFile / 1000000) + " Mb";

                            errore = "The file size must be between " + strMin + " and " + strMax;

                        }


                    }






                }

            }






            if (errore == "") {

                console.log("ok");

                this.uploadFile();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 4000
                });


            }


        },

        btnCancelRenameFolderFile: function () {

            this.$modal.hide('popupRenameFolderFile');

        },

        btnConfirmRenameFolderFile: function () {

            var errore = "";

            var that = this;

            if (that.nameNewElement == "") {
                errore = "Folder / File name required";
            }


            if (errore == "") {
                if (that.validateString(that.nameNewElement) == 0) {
                    errore = "Permitted characters A..Z, a..z and 0..9 and . _ - and space";
                }
            }

            if (errore == "") {
                if (that.nameCurrentElement == that.nameNewElement) {
                    errore = "The new name must be different from the old one";
                }
            }


            console.log("IS FILE: ", that.isFile);

            if (that.isFile == 1) {

                if (that.validateFileName(that.nameNewElement) == 0) {
                    errore = "File name is not valid";
                }

            }

            if (errore == "") {

                console.log("ok");

                that.checkRenameFolderFile();


            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2500
                });


            }

        },




        validateString: function (v_string) {

            var regex = /^[A-Za-z0-9\s._-]+$/;
            var result = 1;

            if (!regex.test(v_string)) {
                result = 0;
            }

            return result;
        },

        validateFileName: function (v_string) {

            console.log("VAL STRING: ", v_string);

            var elements = v_string.toString().split(".");

            console.log("ELE LEGTH", elements.length);

            var result = 1;

            if ((elements.length - 1) == 0) {
                result = 0;
            }

            if ((elements.length - 1) > 1) {
                result = 0;
            }


            console.log("RESULT: ", result);

            return result;
        },

        getPathNavigazionePermissions: function () {


            console.log("NAME_FOLDERS_NAVIGATION: ", window.nameFoldersNavigation);

            // aggiorno label path navigazione
            var path_navigazione = "";

            if (this.getWindowMobile() == 0) {

                for (var x = 0; x < window.nameFoldersNavigation.length; x++) {

                    if (path_navigazione != "") {
                        path_navigazione = path_navigazione + " / ";
                    }

                    path_navigazione = path_navigazione + window.nameFoldersNavigation[x];

                }

            }

            if (this.getWindowMobile() == 1) { // mobile

                if (window.nameFoldersNavigation.length <= 4) {

                    for (var x3 = 0; x3 < window.nameFoldersNavigation.length; x3++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x3];

                    }


                }



                if (window.nameFoldersNavigation.length > 3) {

                    path_navigazione = "..";

                    var x_start = 0;
                    var len_array = window.nameFoldersNavigation.length;
                    if (len_array >= 4 && len_array <= 6) {
                        x_start = 3;
                    }

                    if (len_array > 6 && len_array <= 8) {
                        x_start = 4;
                    }

                    if (len_array > 8 && len_array <= 10) {
                        x_start = 5;
                    }


                    for (var x4 = x_start; x4 < window.nameFoldersNavigation.length; x4++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x4];

                    }

                }

            }

            console.log("PATH NAVIGATION: ", path_navigazione);

            return path_navigazione;

        },



        viewFolder: function () {

            var that = this;

            window.curSrcText = "";


            var ids = $("tr.active-row-doxweb").find(".val_status").attr("data-id");
            var name_folder = $("tr.active-row-doxweb").find(".val_status").attr("name-element");
            var folder_file = $("tr.active-row-doxweb").find(".val_status").attr("folder_file");
            var p_worker = $("tr.active-row-doxweb").find(".val_status").attr("p_worker");
            var p_remove_file = $("tr.active-row-doxweb").find(".val_status").attr("p_remove_file");
            var p_rename_file_folder = $("tr.active-row-doxweb").find(".val_status").attr("p_rename_file_folder");
            var p_otp_delete_file = $("tr.active-row-doxweb").find(".val_status").attr("p_otp_delete_file");
            var p_email = $("tr.active-row-doxweb").find(".val_status").attr("p_email");
            var p_upload = $("tr.active-row-doxweb").find(".val_status").attr("p_upload");

            console.log("VIEW FOLDER ID: ", ids);
            console.log("VIEW FOLDER NAME_FOLDER: ", name_folder);
            console.log("VIEW FOLDER FOLDER_FILE: ", folder_file);
            console.log("VIEW FOLDER P_WORKER: ", p_worker);
            console.log("VIEW FOLDER P_REMOVE_FILE: ", p_remove_file);
            console.log("VIEW FOLDER P_RENAME_FILE_FOLDER: ", p_rename_file_folder);
            console.log("VIEW FOLDER P_OTP_DELETE_FILE: ", p_otp_delete_file);
            console.log("VIEW FOLDER P_EMAIL: ", p_email);
            console.log("VIEW FOLDER P_UPLOAD: ", p_upload);

            if (p_remove_file == null) {
                p_remove_file = 0;
            }
            if (p_remove_file == "") {
                p_remove_file = 0;
            }

            if (p_rename_file_folder == null) {
                p_rename_file_folder = 0;
            }
            if (p_rename_file_folder == "") {
                p_rename_file_folder = 0;
            }

            if (p_otp_delete_file == null) {
                p_otp_delete_file = 0;
            }

            if (p_otp_delete_file == "") {
                p_otp_delete_file = 0;
            }

            if (p_email == "") {
                p_email = 0;
            }

            if (p_upload == "") {
                p_upload = 0;
            }

            var infoParentFolder = [{ p_remove_file: p_remove_file, p_rename_file_folder: p_rename_file_folder, p_otp_delete_file: p_otp_delete_file, p_email: p_email, p_upload: p_upload, data_id: ids, name_element: name_folder }];

            window.infoFolderParent.push(infoParentFolder);


            window.lastFolderSel = folder_file;

            window.curpage = 0;
            window.askFiles = 1;

            window.p_worker = p_worker;

            window.idFoldersNavigation.push(window.id);

            window.nameFoldersNavigation.push(name_folder);

            window.fullPathFoldersNavigation.push(folder_file);


            window.folder_level = parseInt(window.folder_level) + 1;

            window.app = "doxweb";

            window.id = parseInt(ids);


            // aggiorno label path navigazione
            var path_navigazione = "";

            if (that.getWindowMobile() == 0) {

                for (var x = 0; x < window.nameFoldersNavigation.length; x++) {

                    if (path_navigazione != "") {
                        path_navigazione = path_navigazione + " / ";
                    }

                    path_navigazione = path_navigazione + window.nameFoldersNavigation[x];

                }

            }

            if (that.getWindowMobile() == 1) { // mobile

                if (window.nameFoldersNavigation.length <= 4) {

                    for (var x3 = 0; x3 < window.nameFoldersNavigation.length; x3++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x3];

                    }


                }



                if (window.nameFoldersNavigation.length > 3) {

                    path_navigazione = "..";

                    var x_start = 0;
                    var len_array = window.nameFoldersNavigation.length;
                    if (len_array >= 4 && len_array <= 6) {
                        x_start = 3;
                    }

                    if (len_array > 6 && len_array <= 8) {
                        x_start = 4;
                    }

                    if (len_array > 8 && len_array <= 10) {
                        x_start = 5;
                    }


                    for (var x4 = x_start; x4 < window.nameFoldersNavigation.length; x4++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x4];

                    }

                }





            }


            $(".label_url").html(path_navigazione);



            $("#backHeader").show();



            that.$root.$children[0].showProgress = true;


            setTimeout(() => {

                window.table.ajax.reload();

                that.hideShowButtons(false);


            }, 200);



        },







        viewFolderOld: function () {

            window.curSrcText = "";

            var ids = "";
            var name_folder = "";
            var folder_file = "";
            var p_worker = "";

            var p_remove_file = "";
            var p_rename_file_folder = "";
            var p_otp_delete_file = "";
            var p_email = "";
            var p_upload = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");

                    if (name_folder != "") {
                        name_folder = name_folder + ",";
                    }
                    name_folder = name_folder + $(this).attr("name-element");

                    folder_file = $(this).attr("folder_file");

                    p_worker = $(this).attr("p_worker");

                    p_remove_file = $(this).attr("p_remove_file");
                    p_rename_file_folder = $(this).attr("p_rename_file_folder");
                    p_otp_delete_file = $(this).attr("p_otp_delete_file");
                    p_email = $(this).attr("p_email");
                    p_upload = $(this).attr("p_upload");

                }

            });


            if (p_remove_file == null) {
                p_remove_file = 0;
            }
            if (p_remove_file == "") {
                p_remove_file = 0;
            }

            if (p_rename_file_folder == null) {
                p_rename_file_folder = 0;
            }
            if (p_rename_file_folder == "") {
                p_rename_file_folder = 0;
            }

            if (p_otp_delete_file == null) {
                p_otp_delete_file = 0;
            }

            if (p_otp_delete_file == "") {
                p_otp_delete_file = 0;
            }

            if (p_email == "") {
                p_email = 0;
            }

            if (p_upload == "") {
                p_upload = 0;
            }

            var infoParentFolder = [{ p_remove_file: p_remove_file, p_rename_file_folder: p_rename_file_folder, p_otp_delete_file: p_otp_delete_file, p_email: p_email, p_upload: p_upload, data_id: ids, name_element: name_folder }];

            window.infoFolderParent.push(infoParentFolder);


            window.lastFolderSel = folder_file;

            window.curpage = 0;
            window.askFiles = 1;

            window.p_worker = p_worker;

            window.idFoldersNavigation.push(window.id);

            window.nameFoldersNavigation.push(name_folder);

            window.fullPathFoldersNavigation.push(folder_file);


            window.folder_level = parseInt(window.folder_level) + 1;

            window.app = "doxweb";

            window.id = parseInt(ids);


            // aggiorno label path navigazione
            var path_navigazione = "";

            if (this.getWindowMobile() == 0) {

                for (var x = 0; x < window.nameFoldersNavigation.length; x++) {

                    if (path_navigazione != "") {
                        path_navigazione = path_navigazione + " / ";
                    }

                    path_navigazione = path_navigazione + window.nameFoldersNavigation[x];

                }

            }

            if (this.getWindowMobile() == 1) { // mobile

                if (window.nameFoldersNavigation.length <= 4) {

                    for (var x3 = 0; x3 < window.nameFoldersNavigation.length; x3++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x3];

                    }


                }



                if (window.nameFoldersNavigation.length > 3) {

                    path_navigazione = "..";

                    var x_start = 0;
                    var len_array = window.nameFoldersNavigation.length;
                    if (len_array >= 4 && len_array <= 6) {
                        x_start = 3;
                    }

                    if (len_array > 6 && len_array <= 8) {
                        x_start = 4;
                    }

                    if (len_array > 8 && len_array <= 10) {
                        x_start = 5;
                    }


                    for (var x4 = x_start; x4 < window.nameFoldersNavigation.length; x4++) {

                        if (path_navigazione != "") {
                            path_navigazione = path_navigazione + " / ";
                        }

                        path_navigazione = path_navigazione + window.nameFoldersNavigation[x4];

                    }

                }





            }


            $(".label_url").html(path_navigazione);



            $("#backHeader").show();



            this.$root.$children[0].showProgress = true;


            setTimeout(() => {

                window.table.ajax.reload();

                this.hideShowButtons(false);


            }, 200);


        },


        btnCancelNewFolder: function () {

            this.$modal.hide('popupAddFolder');

        },

        btnConfirmNewFolder: function () {

            var errore = "";

            var that = this;

            if (that.nameNewFolder == "") {
                errore = "Folder name required";
            }


            if (errore == "") {
                if (that.validateString(that.nameNewFolder) == 0) {
                    errore = "Permitted characters A..Z, a..z and 0..9 and . _ - and space";
                }
            }




            if (errore == "") {

                console.log("ok");

                that.createNewFolder(that.nameNewFolder);


            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2500
                });


            }



        },



        uploadFile: async function () {


            var that = this;

            that.$modal.hide('popupUploadFile');

            var is_folder_sel = 0;
            var folder_file = "";
            var folder_id = "";
            var folder_text = "";
            var p_email = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    folder_file = $(this).attr("folder_file");
                    folder_id = $(this).attr("data-id");
                    folder_text = $(this).attr("name-element");
                    p_email = $(this).attr("p_email");

                    is_folder_sel = 1;

                }

            });

            if (folder_file == "") {
                folder_file = window.lastFolderSel;

                p_email = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_email"];
                folder_id = window.infoFolderParent[window.infoFolderParent.length - 1][0]["data_id"];
                folder_text = window.infoFolderParent[window.infoFolderParent.length - 1][0]["name_element"];

                console.log("P_EMAIL: ", p_email);
                console.log("FOLDER_ID: ", folder_id);
                console.log("FOLDER_TEXT: ", folder_text);

            }

            var a = window.$cookies.get("a");

            if (a == "S") {

                p_email = 1;

            }
            else {

                if (p_email == "") {
                    p_email = 0;
                }

            }


            if (p_email == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apidoxweb.uploadFile(
                    folder_file,
                    false,
                    "cedolini",
                    $("#input-file-attach")[0].files[0]
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from uploadFile", res);

                    if (res.data.result == "OK") {


                        that.$swal({
                            icon: "success",
                            text: "File uploaded successfully",
                            showConfirmButton: false,
                            timer: 2000
                        });


                        if (is_folder_sel == 1) {

                            setTimeout(() => {


                                that.viewFolder();


                            }, 200);


                        }

                        if (is_folder_sel == 0) {

                            setTimeout(() => {

                                window.app = "doxweb";

                                window.table.ajax.reload();


                            }, 200);


                        }

                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: "Error during upload !",
                            showConfirmButton: false,
                            timer: 3000
                        });

                    }








                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;
                    console.log(msg);

                    that.$swal({
                        icon: "error",
                        text: "Error during upload !",
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }



            if (p_email == 1) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response2 = await apidoxweb.uploadFile(
                    folder_file,
                    false,
                    "cedolini",
                    $("#input-file-attach")[0].files[0]
                ).then(async (res2) => {

                    //that.$root.$children[0].showProgress = false;
                    console.log("res from uploadFile", res2);

                    if (res2.data.result == "OK") {

                        var v_object = "upload";
                        var login = atob(window.$cookies.get("token"));
                        var useremail = this.userMail;

                        console.log("FOLDER_FILE: ", folder_file);
                        console.log("OBJECT: ", v_object);
                        console.log("FOLDER_ID: ", folder_id);
                        console.log("IS_FOLDER_SEL: ", is_folder_sel);
                        console.log("FOLDER_TEXT: ", folder_text);
                        console.log("LOGIN: ", login);
                        console.log("USEREMAIL: ", useremail);

                        var response3 = await apidoxweb.notifyByFolder(
                            v_object,
                            folder_id,
                            folder_text,
                            login,
                            useremail
                        ).then((res3) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from notifyByFolder", res3);

                            that.$swal({
                                icon: "success",
                                text: "File uploaded successfully",
                                showConfirmButton: false,
                                timer: 2000
                            });


                            if (is_folder_sel == 1) {

                                setTimeout(() => {


                                    that.viewFolder();


                                }, 200);


                            }

                            if (is_folder_sel == 0) {

                                setTimeout(() => {

                                    window.app = "doxweb";

                                    window.table.ajax.reload();


                                }, 200);


                            }





                        }).catch(err3 => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err3);
                            var msg3 = err3.response.data.Error;
                            console.log(msg3);

                            that.$swal({
                                icon: "error",
                                text: "Error during upload !",
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("ERrori", "Non è stato possibile salvare i dati");
                            console.log("response", response3);

                        }

                        );

                    }
                    else {

                        that.$swal({
                            icon: "error",
                            text: "Error during upload !",
                            showConfirmButton: false,
                            timer: 3000
                        });



                    }





                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg2 = err2.response.data.Error;
                    console.log(msg2);

                    that.$swal({
                        icon: "error",
                        text: "Error during upload !",
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response2);

                }

                );

            }









        },






        checkRenameFolderFile: async function () {


            var that = this;


            var key = "";
            var is_file = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    is_file = $(this).attr("is-file");
                    if (is_file == 0) {
                        key = $(this).attr("folder_file");
                    }

                    if (is_file == 1) {
                        key = $(this).attr("file-path");
                    }

                }

            });



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.checkFolderFileExist(
                key,
                that.nameCurrentElement,
                that.nameNewElement,
                is_file
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkFolderFileExist", res);

                if (res.data.Result == "OK") {

                    console.log("ok");


                    if (is_file == 0) {


                        that.renameFolderFile();
                    }


                    if (is_file == 1) {


                        that.renameFile();
                    }


                }
                else {


                    if (is_file == 0) {


                        that.$swal({
                            icon: "error",
                            text: "Folder already exist",
                            showConfirmButton: false,
                            timer: 2500
                        });



                    }

                    if (is_file == 1) {


                        that.$swal({
                            icon: "error",
                            text: "File already exist",
                            showConfirmButton: false,
                            timer: 2500
                        });



                    }

                }








            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },


        renameFolderFile: async function () {


            var that = this;

            var app = "doxweb";
            var action = "renameFolder";

            var uname = "";

            var a = window.$cookies.get("a");

            if (a == "S") {
                uname = "admin";
            }

            var key = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    var is_file = $(this).attr("is-file");

                    if (is_file == 0) {

                        key = btoa($(this).attr("folder_file"));

                    }

                    if (is_file == 1) {

                        key = $(this).attr("file-path");


                        uname = "";


                        app = "";

                    }



                }

            });


            var v_new = that.nameNewElement;
            var v_old = that.nameCurrentElement;
            var v_a = window.$cookies.get("token");
            var v_b = window.$cookies.get("token_system");


            console.log("ACTION: ", action);
            console.log("UNAME: ", uname);
            console.log("KEY: ", key);
            console.log("NEW: ", v_new);
            console.log("OLD: ", v_old);
            console.log("A: ", v_a);
            console.log("B: ", v_b);
            console.log("APP: ", app);



            that.$modal.hide('popupRenameFolderFile');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.renameFolderFile(
                action,
                uname,
                key,
                v_new,
                v_old,
                v_a,
                v_b,
                app
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from renameFolderFile", res);


                window.app = app;

                setTimeout(() => {

                    that.$swal({
                        icon: "success",
                        text: "Rename done successfully",
                        showConfirmButton: false,
                        timer: 2000
                    });


                    window.table.ajax.reload();


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },


        renameFile: async function () {


            var that = this;

            var app = "doxweb";
            var action = "renameFolder";

            var uname = "";

            var a = window.$cookies.get("a");

            if (a == "S") {
                uname = "admin";
            }

            var key = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    var is_file = $(this).attr("is-file");

                    if (is_file == 0) {

                        key = btoa($(this).attr("folder_file"));

                    }

                    if (is_file == 1) {

                        key = $(this).attr("file-path");


                        uname = "";


                        app = "";

                    }



                }

            });


            var v_new = that.nameNewElement;
            var v_old = that.nameCurrentElement;
            var v_a = window.$cookies.get("token");
            var v_b = window.$cookies.get("token_system");


            console.log("ACTION: ", action);
            console.log("UNAME: ", uname);
            console.log("KEY: ", key);
            console.log("NEW: ", v_new);
            console.log("OLD: ", v_old);
            console.log("A: ", v_a);
            console.log("B: ", v_b);
            console.log("APP: ", app);



            that.$modal.hide('popupRenameFolderFile');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.renameFile(
                action,
                uname,
                key,
                v_new,
                v_old
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from renameFile", res);


                window.app = "doxweb";

                setTimeout(() => {

                    that.$swal({
                        icon: "success",
                        text: "Rename done successfully",
                        showConfirmButton: false,
                        timer: 2000
                    });


                    window.table.ajax.reload();


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },




        createNewFolder: async function (name_folder) {


            var login = "";

            var a = window.$cookies.get("a");

            if (a == "S") {
                login = "admin";
            }
            else {

                login = atob(window.$cookies.get("token"));

            }

            var file_path = "";

            var is_folder_sel = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    file_path = $(this).attr("folder_file");

                    is_folder_sel = 1;

                }

            });


            if (file_path == "") {
                file_path = window.lastFolderSel;
            }

            var parPath = file_path + name_folder + "/";

            console.log("USER: ", login);
            console.log("NAME FOLDER: ", name_folder);
            console.log("FILE PATH: ", file_path);
            console.log("PAR PATH: ", parPath);

            var that = this;

            that.$modal.hide('popupAddFolder');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.createFolder(
                login,
                name_folder,
                parPath
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createFolder", res);


                if (res.data.Errore == "") {

                    that.$swal({
                        icon: "success",
                        text: "Folder created successfully",
                        showConfirmButton: false,
                        timer: 2000
                    });


                    if (is_folder_sel == 1) {

                        setTimeout(() => {


                            that.viewFolder();


                        }, 200);


                    }

                    if (is_folder_sel == 0) {

                        setTimeout(() => {

                            window.app = "doxweb";

                            window.table.ajax.reload();


                        }, 200);


                    }

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2500
                    });

                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },


        downloadFile: async function (file_path) {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidoxweb.downloadFile(
                file_path
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from downloadFile", res);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );






        },


        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },



        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        getWindowMobile: function () {

            if (window.innerWidth <= 768) {

                return 1;

            }
            else {

                return 0;

            }

        },


        hideShowButtonsSelRow: function () {






            var tot = 0;
            var tot_file = 0;

            var a = window.$cookies.get("a");

            var models = window.$cookies.get("models");

            console.log(models);

            var view_btn_res_qt_code = 1;

            if (models != null) {

                var elements = models.split(",");

                for (var x = 0; x <= elements.length - 1; x++) {

                    if ((parseInt(elements[x]) == 9) || (parseInt(elements[x]) == 10)) {

                        view_btn_res_qt_code = 0;

                    }


                }


            }

            console.log("VIEW: ", view_btn_res_qt_code);

            var is_file = 0;
            var p_remove_folder = 0;
            var p_upload = 0;
            var p_create_folder = 0;
            var p_edit_permissions = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    is_file = $(this).attr("is-file");
                    p_remove_folder = $(this).attr("p_remove_folder");
                    p_upload = $(this).attr("p_upload");
                    p_create_folder = $(this).attr("p_create_folder");
                    p_edit_permissions = $(this).attr("p_edit_permissions");

                    if (is_file == 1) {
                        tot_file = tot_file + 1;
                    }

                }

            });

            setTimeout(() => {

                var pulsantis = [];


                if (a == "S") { // SU


                    if (is_file == 0) { // folder



                        if (tot == 1) {

                            pulsantis.push({
                                text: "Add",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                                link: "/settings",
                                id: "btn_create",
                                disabled: false,
                                title: "Create",
                                width: 30
                            });

                            pulsantis.push({
                                text: "Delete",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/BOTTOM 6 cancella file.png"),
                                link: "/info",
                                id: "btn_remove",
                                disabled: false,
                                title: "Remove",
                                width: 30
                            });

                            pulsantis.push({
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                                link: "/info",
                                id: "btn_upload",
                                disabled: false,
                                title: "Upload",
                                width: 30
                            });




                        }

                        if (tot == 0) {

                            pulsantis.push({
                                text: "Add",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                                link: "/settings",
                                id: "btn_create",
                                disabled: false,
                                title: "Create",
                                width: 30
                            });




                            if (window.folder_level > 0) {


                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                                    link: "/info",
                                    id: "btn_upload",
                                    disabled: false,
                                    title: "Upload",
                                    width: 30
                                });



                            }





                        }



                    }


                    if (tot == 1) {

                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/icon_edit_doxweb.png"),
                            link: "/info",
                            id: "btn_rename",
                            disabled: false,
                            title: "Rename",
                            width: 30
                        });



                        // pulsantis.push({
                        //     text: "Edit",
                        //     icon: "mdi-home-circle",
                        //     image: this.getUrlDomain("public/_lib/img/BOTTOM 5 vedi file.png"),
                        //     link: "/info",
                        //     id: "btn_view",
                        //     disabled: false,
                        //     title: "View",
                        //     width: 30
                        // });


                        if (is_file == 1) {

                            pulsantis.push({
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/BOTTOM 4 download file.png"),
                                link: "/info",
                                id: "btn_download",
                                disabled: false,
                                title: "Download",
                                width: 30
                            });

                        }



                    }

                    if (tot > 0) {

                        if (tot == tot_file) {

                            pulsantis.push({
                                text: "Delete",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/BOTTOM 6 cancella file.png"),
                                link: "/info",
                                id: "btn_remove",
                                disabled: false,
                                title: "Remove",
                                width: 30
                            });

                        }


                        if (view_btn_res_qt_code == 1) {

                            if (tot == 1) {

                                if (is_file == 0) {

                                    pulsantis.push({
                                        text: "Edit",
                                        icon: "mdi-home-circle",
                                        image: this.getUrlDomain("public/_lib/img/permissions_doweb.png"),
                                        link: "/info",
                                        id: "btn_permissions",
                                        disabled: false,
                                        title: "Permissions",
                                        width: 30
                                    });


                                }




                            }


                        }



                    }


                    if (window.folder_level > 0) {

                        // pulsantis.push({
                        //     text: "Back",
                        //     icon: "mdi-arrow-left",
                        //     image: this.getUrlDomain("public/_lib/img/back-doxweb.png"),
                        //     link: "/confOrariChoice",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Back",
                        //     width: 30
                        // });

                        // pulsantis.push({
                        //     text: "Back",
                        //     icon: "mdi-arrow-left",
                        //     image: this.getUrlDomain("public/_lib/img/back_root_doxweb.png"),
                        //     link: "/confOrariChoice",
                        //     id: "btn_back_root",
                        //     disabled: false,
                        //     title: "Back Root",
                        //     width: 30
                        // });

                    }


                }



                if (a == "N") { // non SU

                    if (tot == 1) { // un solo elemento

                        if (is_file == 0) { // folder


                            if (p_create_folder == 1) {

                                pulsantis.push({
                                    text: "Add",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                                    link: "/settings",
                                    id: "btn_create",
                                    disabled: false,
                                    title: "Create",
                                    width: 30
                                });


                            }

                            if (p_remove_folder == 1) {

                                pulsantis.push({
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/BOTTOM 6 cancella file.png"),
                                    link: "/info",
                                    id: "btn_remove",
                                    disabled: false,
                                    title: "Remove",
                                    width: 30
                                });


                            }

                            if (p_upload == 1) {

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                                    link: "/info",
                                    id: "btn_upload",
                                    disabled: false,
                                    title: "Upload",
                                    width: 30
                                });


                            }



                            if (p_edit_permissions == 1) {

                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/permissions_doweb.png"),
                                    link: "/info",
                                    id: "btn_permissions",
                                    disabled: false,
                                    title: "Permissions",
                                    width: 30
                                });


                            }



                            // pulsantis.push({
                            //     text: "Edit",
                            //     icon: "mdi-home-circle",
                            //     image: this.getUrlDomain("public/_lib/img/BOTTOM 5 vedi file.png"),
                            //     link: "/info",
                            //     id: "btn_view",
                            //     disabled: false,
                            //     title: "View",
                            //     width: 30
                            // });


                        }

                        if (is_file == 1) {


                            var info_parent = window.infoFolderParent[window.infoFolderParent.length - 1];

                            console.log("INFOR PARENT FOLDER: ", info_parent[0]["p_remove_file"]);

                            console.log("INFOR PARENT FOLDER: ", info_parent[0]["p_rename_file_folder"]);

                            if (info_parent[0]["p_remove_file"] == 1) {


                                pulsantis.push({
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/BOTTOM 6 cancella file.png"),
                                    link: "/info",
                                    id: "btn_remove",
                                    disabled: false,
                                    title: "Remove",
                                    width: 30
                                });


                            }


                            if (info_parent[0]["p_rename_file_folder"] == 1) {


                                pulsantis.push({
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: this.getUrlDomain("public/_lib/img/icon_edit_doxweb.png"),
                                    link: "/info",
                                    id: "btn_rename",
                                    disabled: false,
                                    title: "Rename",
                                    width: 30
                                });


                            }


                            pulsantis.push({
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/BOTTOM 4 download file.png"),
                                link: "/info",
                                id: "btn_download",
                                disabled: false,
                                title: "Download",
                                width: 30
                            });


                        }



                    }


                    if (window.folder_level > 0) {

                        // pulsantis.push({
                        //     text: "Back",
                        //     icon: "mdi-arrow-left",
                        //     image: this.getUrlDomain("public/_lib/img/back-doxweb.png"),
                        //     link: "/confOrariChoice",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Back",
                        //     width: 35
                        // });

                        // pulsantis.push({
                        //     text: "Back",
                        //     icon: "mdi-arrow-left",
                        //     image: this.getUrlDomain("public/_lib/img/back_root_doxweb.png"),
                        //     link: "/confOrariChoice",
                        //     id: "btn_back_root",
                        //     disabled: false,
                        //     title: "Back Root",
                        //     width: 35
                        // });

                    }




                }






                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        getUrlApiDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "api/newdoxweb/" + nome_metodo;

        },




        setupGrid: function () {


            var filtri = `<img src='/emt/img/refresh-doxweb.png' style='border:0!important;' class='selaggiorna' title='Refresh' /><img src='/emt/img/back_to_root_top_doxweb.png' style='border:0!important;' class='selbacktoroot' title='Back Root' />`;


            var pointerVue = this;

            pointerVue.$root.$children[0].showProgress = true;


            $(document).ready(function () {


                window.columns = [{
                    data: 'sel_row',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '0.2%',
                },

                {
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '0.2%',
                },

                {
                    data: 'type_element',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '0.2%',
                },

                {
                    data: 'text',
                    orderable: true,
                    searchable: true,
                    name: '',
                    width: '8%',
                },

                {
                    data: 'date',
                    orderable: true,
                    searchable: true,
                    name: '',
                    width: '3%',

                    render: function (data, row) {
                        console.log(data, row);

                        var date = "";
                        try {

                            date = data.substring(0, 10);
                        } catch (error) {
                            console.log(error);
                        }

                        var hour = "";
                        try {

                            hour = data.split(" ")[1].slice(0, 5);
                        } catch (error) {
                            console.log(error);
                        }

                        var html = "<div class='cl-div-hid'>" + data + "</div><div class='font-row-grid-datetime'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                        return html;
                    }

                },

                {
                    data: 'status',
                    orderable: true,
                    searchable: true,
                    name: '',
                    width: '1%',
                },

                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: true,
                    paging: false,
                    serverSide: false,


                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,



                    ajax: {
                        url: pointerVue.getUrlApiDomain("getMyFolders"),
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;


                            valori.curpage = window.curpage;
                            valori.p_worker = window.p_worker;
                            valori.askFiles = window.askFiles;
                            valori.folder_level = window.folder_level;
                            valori.id = window.id;
                            valori.login = window.login;
                            valori.useremail = window.useremail;
                            valori.a = window.a;
                            valori.b = window.b;
                            valori.app = window.app;

                            valori.curSrcText = window.curSrcText;

                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.$root.$children[0].showProgress = false;

                        }, 3000);


                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });



                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();




                        if (window.folder_level > 0) {

                            $(".selbacktoroot").show();
                            $("#backHeader").show();

                        }
                        else {

                            $(".selbacktoroot").hide();
                            $("#backHeader").hide();

                        }






                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlApiDomain("getMyFolders"),
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Doxweb</div>
                    ` + filtri + `

                    <div class="label_url"></div>

                    <img id="backHeader" src="/emt/img/back-doxweb-header.png" style="width:35px;height:35px;display:none;cursor:pointer"  title='back' />

                
                </div>

               


                <div><img src='/emt/img/cerca-doxweb.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                    setTimeout(() => {

                        $("#fldSearch").focus();

                    }, 200);

                });



                $(".selaggiorna").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.hideShowButtons(false);

                    window.app = "doxweb";

                    //pointerVue.showSpinner = true;
                    //pointerVue.$root.$children[0].colorProgress = "#6cc115";
                    pointerVue.$root.$children[0].showProgress = true;


                    window.table.ajax.reload();



                });




                $(".selbacktoroot").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.backToRoot();

                });






                $('body').on('click', '.cont_doxweb tr', function () {


                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row-doxweb").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row-doxweb");

                            if (hasClassActive) { // è già selezionata



                                if (row_data.isDir) { // è una folder


                                    pointerVue.viewFolder();

                                }
                                else {

                                    console.log("active-row-doxweb");

                                    $(this).find(".val_status").prop("checked", false);

                                    $(this).removeClass("active-row-doxweb");



                                }





                            }


                            if (!hasClassActive) { // non è selezionata

                                console.log("NOT active-row-doxweb");


                                $(".val_status").prop("checked", false);

                                $("tr").find("td:eq(4)").find("img").remove();

                                $("tr").removeClass("active-row-doxweb");



                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row-doxweb");


                                if (row_data.isDir) { // è una folder

                                    $(this).css("cursor", "pointer");

                                    var ele_td = $(".active-row-doxweb").find("td:eq(4)");

                                    console.log("ELEMENT TD: ", ele_td);

                                    var img = $("<img>");
                                    img.attr("src", pointerVue.getUrlDomain("public/_lib/img/BOTTOM 5 vedi file.png"));
                                    img.css("width", "30px");
                                    img.css("height", "30px");
                                    img.css("float", "right");
                                    img.css("margin-right", "20px");


                                    ele_td.html(img);



                                }






                            }

                            window.id_par_sel = $("tr.active-row-doxweb").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);




                });




            });

        },


        hideShowButtons: async function (v_enable) {

            console.log(v_enable);

            console.log("ARRAY FOLDER PARENT: ", window.infoFolderParent);

            setTimeout(() => {


                var a = window.$cookies.get("a");

                console.log("VALORE A: ", a);

                var pulsantis = [];


                if (a == "S") {

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: this.getUrlDomain("public/_lib/img/DOXWEB BOTTOM 54 più.png"),
                        link: "/settings",
                        id: "btn_create",
                        disabled: false,
                        title: "Create",
                        width: 30
                    });

                    if (window.folder_level > 0) {


                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                            link: "/info",
                            id: "btn_upload",
                            disabled: false,
                            title: "Upload",
                            width: 30
                        });

                        pulsantis.push({
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: this.getUrlDomain("public/_lib/img/permissions_doweb.png"),
                            link: "/info",
                            id: "btn_permissions",
                            disabled: false,
                            title: "Permissions",
                            width: 30
                        });

                    }


                }





                if (window.folder_level > 0) {


                    if (a == "N") {

                        p_upload = window.infoFolderParent[window.infoFolderParent.length - 1][0]["p_upload"];

                        if (p_upload == 1) {


                            pulsantis.push({
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomain("public/_lib/img/upload_doxweb.png"),
                                link: "/info",
                                id: "btn_upload",
                                disabled: false,
                                title: "Upload",
                                width: 30
                            });


                        }



                    }





                    // pulsantis.push({
                    //     text: "Back",
                    //     icon: "mdi-arrow-left",
                    //     image: this.getUrlDomain("public/_lib/img/back-doxweb.png"),
                    //     link: "/confOrariChoice",
                    //     id: "btn_back",
                    //     disabled: false,
                    //     title: "Back",
                    //     width: 30
                    // });


                }



                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },




        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/DOXWEB BOTTOM 54 più.png",
                        link: "/settings",
                        id: "btn_create",
                        disabled: false,
                        width: 35
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_rename",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/DOXWEB BOTTOM 49 cestino.png",
                        link: "/info",
                        id: "btn_remove",
                        disabled: false,
                        width: 35
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },



        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },


    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}


.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cl-date {
    font-size: 10px;
    color: gray;
}


.cl-div-hid {
    display: none;
}


.cont_doxweb .font-row-grid-datetime {
    font-size: 10px !important;
    width: 70px !important;
}


.cont_doxweb .dataTables_empty {
    display: none !important;
}

.clIconSwallGreen {
    border-color: #6cc115 !important;
    color: #6cc115 !important;
}


.v-icon i {
    color: #6cc115 !important;
}

.clConfirmGroupOrMail {
    margin-top: -19px !important;
}

.primary-text-green {
    color: #6cc115 !important;
    caret-color: #6cc115 !important;
}

.clColorGreen {
    color: #6cc115 !important;
}

.v-label-green {
    color: #6cc115 !important;
}

.v-label--active {
    color: #6cc115 !important;
}

.v-radio .v-icon {
    color: #6cc115 !important;
    accent-color: #6cc115 !important;
}

.v-radio:checked {
    color: #6cc115 !important;
}


input[type=radio]:checked {
    color: #6cc115 !important;
}

#fldSearch {
    caret-color: #6cc115 !important;
}

.clBorderBottomGreen {
    border-bottom: 3px solid #6cc115 !important;
    border-bottom-color: #6cc115 !important;
    caret-color: #6cc115 !important;
}


.clMail {

    padding-bottom: 0px !important;
    height: 30px !important;
    padding-top: 0px !important;
    margin-top: -18px !important;

}


.cntRi {
    align-items: center;
    display: flex;
    flex-direction: row;
    min-height: 23px;
    margin-left: 10px;
}

.clTitlePermissions {
    min-width: 300px;
    color: white;
    padding: 3px;
    background: rgb(157, 155, 155);
    font-weight: bold;
    font-size: 13px;
    margin-left: 10px;
    margin-right: 10px;
}

.contpopuppermissions .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}



.contpopuppermissions .flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contpopuppermissions .v-label {
    font-weight: bold !important;
    font-size: 11px !important;
    color: black !important
}


.rowPrevFile .dropify-wrapper {
    height: 187px !important;
    width: 363px !important;
    margin-bottom: 20px;
    margin-left: 1px;
}


.label_url {
    color: black;
    margin-left: 15px;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/smartb/img/FiltroNucleo.png);
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_doxweb {
    overflow-x: auto !important;
}

.cont_doxweb #myTable {
    margin-right: 3px;
    width: 585px !important;
}

.cont_doxweb table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_doxweb table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_doxweb th {
    padding-left: 5px !important;
}

.cont_doxweb ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_doxweb .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}


.cont_doxweb #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #6cc115;

}

.cont_doxweb div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_doxweb #myTable_wrapper {
    padding-top: 0px;
    font-size: 11px;
    color: black;
    font-weight: bold;
    min-width: 600px;
    max-width: 600px;
    width: 600px;
}

.cont_doxweb .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 2vw
}


.cont_doxweb .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.cont_doxweb .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}



.cont_doxweb .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_doxweb .checkall {
    width: 13px;
    height: 13px;
}


.cont_doxweb .val_status {
    width: 10px !important;
}

.cont_doxweb .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_doxweb .selbacktoroot {
    max-width: 35px;
    min-height: 35px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 35px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    display: none;
}


.cont_doxweb .selbacktheader {
    border: 0 !important;
    width: 35px !important;
    height: 35px !important;
    cursor: pointer;
    display: none;
}



.cont_doxweb .selaggiorna {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_doxweb .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 86px;
    padding-left: 10px;
    justify-content: inherit;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemAzienda {
    margin-left: 15px;
}



@media screen and (max-width: 960px) {

    .cont_doxweb .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_doxweb #myTable {
        /* margin-top:54px; */
    }

    .cont_doxweb #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_doxweb #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;

    }

    .cont_doxweb #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_doxweb .table-responsive {
        padding: 0px;
        min-width: 100vw !important;
        max-width: 100vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_doxweb #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: auto !important;
    }

    .cont_doxweb .cl_num {
        margin-left: -2px !important;
    }


    .cont_doxweb .cl_checkall {
        padding-left: 1px;
        padding-top: 2px;
    }

    .label_url {
        color: black;
        margin-left: 15px;
        font-size: 8px;
    }



}
</style>
